import { CustomSVGIconProps } from './../Icons/icon.type'
import * as TrustmarkIconsModule from '../Icons/VD/Trustmark/'
import { FC } from 'react'

const TrustMarkerLinks = {
  thuiswinkel_NL: 'https://www.thuiswinkel.org/leden/vision-direct/certificaat',
  post_NL: '/nl-nl/info/bezorgmethoden',
  selloConfianzaOnline_ES: 'https://www.confianzaonline.es/empresas/visiondirect.htm',
  becommerce_FR: 'https://becom.digital/nl/',
  becommerce_NL: 'https://becom.digital/nl/',
}

export type TrustMarkIcon = FC<CustomSVGIconProps>

export const getTrustMarkLinkByName = (name: string): string => {
  return TrustMarkerLinks[name]
}

export const getTrustMarkIconByName = (iconName: string): TrustMarkIcon | null => {
  iconName = `Icon${iconName.charAt(0).toUpperCase()}${iconName.slice(1)}`.replace('_', '').toLocaleLowerCase()

  for (const key in TrustmarkIconsModule) {
    if (key.toLowerCase() === iconName) {
      return TrustmarkIconsModule[key]
    }
  }

  return null
}
