import { i18n } from 'next-i18next'
import { useSite } from '@foundation/hooks/useSite'
import { CommerceEnvironment } from '@constants/common'

export const useWebsitePhoneNumber = () => {
  const { mySite } = useSite()

  const country = i18n?.language
  const WebsitePhoneNumbers = mySite?.xStoreCfg?.websitePhoneNumber || []
  const phoneNumbers = WebsitePhoneNumbers.map(p => {
    const locale = CommerceEnvironment.languageMap[p.locale]
    const phone = p.number

    return { locale, phone }
  })

  const phoneNumber: string = phoneNumbers?.find(phone => phone?.locale === country?.replace('-', '_'))?.phone ?? ''

  return phoneNumber
}
