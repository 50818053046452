import { IconButton } from '@components/UI/IconButton'
import styled from '@mui/material/styles/styled'

export const NavIcon = styled('div', {
  name: 'NavigationIcon',
  slot: 'NavIcon',
})(({ theme }) => ({
  display: 'inherit',
  gap: theme.spacing(12.5),

  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(2),

    '[data-element-id="X_X_MainNav_Bag"]': {
      display: 'none',
    },

    '[data-element-id="X_X_MainNav_Menu"]': {
      display: 'none',
    },
  },
}))

export const ContentIconButton = styled('div', {
  name: 'NavigationIcon',
  slot: 'IconButton',
})(() => ({
  display: 'inherit',
}))

export const ContentIcon = styled(IconButton, {
  name: 'NavigationIcon',
  slot: 'Icon',
})(({ theme }) => ({
  color: theme.palette.common.white,

  [theme.breakpoints.up('md')]: {
    color: theme.palette.neutral.main,
  },
}))
