import styled from '@mui/material/styles/styled'
import { PaymentMethodPositions } from '../../../../types/paymentMethods'

function translatePositionToCss(position) {
  switch (position) {
    case 'right':
      return 'right'
    case 'center':
      return 'center'
    case 'left':
    default:
      return 'left'
  }
}

export const PaymentMethodSpanWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(4),
  },
}))

export const PaymentMethodImageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(4),
}))

export const PaymentMethodWrapper = styled('div', {
  name: 'PaymentMethod',
  shouldForwardProp: prop => prop !== 'position',
})<{
  position: PaymentMethodPositions
}>(({ theme, position }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: translatePositionToCss(position),
  ...theme.typography.button,
  ['svg']: {
    fontSize: 32,
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'column',
  },
}))
