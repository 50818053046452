import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { loginStatusSelector } from '@features/user/selector'
import { setIsReturningCustomerButtonClicked } from '@features/user/slice'
import { ACCOUNT_CHILDREN, ACCOUNT, SIGNIN } from '@constants/routes'
import {
  StyleReorderIconWrapper,
  StyleReorderTextWrapper,
  StyledIconReturningCustomer2,
  StyledReorder,
  StyledReorderText,
  StyledReorderWrapper,
} from './ReturningCustomerLoginButon.style'

const ReturningCustomerLoginButton = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(loginStatusSelector)

  const handleReturningCustomerButtonClick = () => {
    dispatch(setIsReturningCustomerButtonClicked(true))
    const redirectRoute = `${ACCOUNT}/${ACCOUNT_CHILDREN.ORDER_HISTORY}`

    if (isLoggedIn) {
      router.push(redirectRoute)
    } else {
      router.push({
        pathname: SIGNIN,
        query: {
          forcedLogin: 'true',
          redirect: redirectRoute,
        },
      })
    }
  }

  return (
    <StyledReorderWrapper onClick={() => handleReturningCustomerButtonClick()}>
      <StyledReorder>
        <StyleReorderIconWrapper>
          <StyledIconReturningCustomer2 />
        </StyleReorderIconWrapper>
        <StyleReorderTextWrapper>
          <StyledReorderText variant="body2">{t('ReturningCustomerLoginButton.reorder.returning')}</StyledReorderText>
          <StyledReorderText variant="button" data-name="btn_mobile_reorder_3clicks">
            {t('ReturningCustomerLoginButton.reorder.cta')}
          </StyledReorderText>
        </StyleReorderTextWrapper>
      </StyledReorder>
    </StyledReorderWrapper>
  )
}

export default ReturningCustomerLoginButton
