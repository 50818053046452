import { useEffect, useMemo, useRef } from 'react'
import Axios, { Canceler } from 'axios'
import { useSelector } from 'react-redux'
import { useLazyGetCartQuery } from '@features/order/query'
import { useSite } from '../../foundation/hooks/useSite'
import { CartPayload } from '@typesApp/cart'
import { cartSelector } from '@features/order/selector'
import { INVENTORY } from '@constants/common'
import { currentContractIdSelector } from '@features/contract/selector'

export const useCart = (payload: CartPayload | null = null) => {
  const { mySite } = useSite()
  const cart = useSelector(cartSelector)
  const contractId = useSelector(currentContractIdSelector)
  const cancels: Canceler[] = useMemo(() => [], [])

  const [getCart, { isUninitialized: isCartUnitialized, isFetching: cartLoading, isSuccess: isCartGetSuccess }] =
    useLazyGetCartQuery()

  const checkInventory = useMemo<boolean>(() => mySite.inventorySystem === INVENTORY.NON_ATP, [mySite.inventorySystem])

  const timestampRef = useRef(Date.now())
  const { storeID, defaultCurrencyID } = mySite

  const payloadBase = useMemo<CartPayload>(() => {
    if (payload) return payload
    return {
      storeId: storeID,
      currency: defaultCurrencyID || '',
      cancelToken: new Axios.CancelToken(c => cancels.push(c)),
      checkInventory,
      contractId,
    }
  }, [cancels, storeID, defaultCurrencyID, payload])

  const fetchCart = async (props: {
    fetchCatentries?: boolean
    fetchShippingInfo?: boolean
    refetch?: boolean
    fetchPrescriptionDetails?: boolean
    queryParams?: Record<string, string>
  }) => {
    const {
      fetchCatentries = false,
      fetchShippingInfo = false,
      refetch = false,
      fetchPrescriptionDetails = false,
      queryParams = {},
    } = props

    return await getCart({
      ...payloadBase,
      fetchCatentries,
      fetchShippingInfo,
      fetchPrescriptionDetails,
      refetch,
      sessionId: timestampRef.current,
      queryParams,
      updateProducts: true,
    })
  }

  useEffect(() => {
    return () => {
      cancels.forEach(cancel => cancel())
    }
  }, [cancels])

  return {
    cart,
    isCartUnitialized,
    cartLoading,
    isCartGetSuccess,
    fetchCart,
  }
}
