import { GridContainer } from '@components/UI/Grid'
import { Button } from '@components/UI/Button'
import styled from '@mui/material/styles/styled'

export const StyledNewsletterWrapper = styled(GridContainer, {
  name: 'NewsletterWrapper',
})(({ theme }) => ({
  display: 'block',
  width: 'auto',

  [theme.breakpoints.up('sm')]: {
    padding: 0,
  },
}))

export const ItemContent = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  lineHeight: '1.4',
  color: theme.palette.common.white,
  minHeight: theme.spacing(18),

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    minHeight: theme.spacing(22),
    fontSize: theme.spacing(5),
    lineHeight: '1.4',
  },
}))

export const RightItemContent = styled(ItemContent)(() => ({
  justifyContent: 'end',
}))
