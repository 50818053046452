import { IconReturningCustomerTwo } from '@components/UI/Icons/VD/General'
import { Typography } from '@mui/material'
import styled from '@mui/material/styles/styled'

export const StyledReorderWrapper = styled('div', {
  name: 'BaseLayout',
  slot: 'Reorder',
})(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(8),
  cursor: 'pointer',

  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}))

export const StyleReorderIconWrapper = styled('div', {
  name: 'BaseLayout',
  slot: 'ReorderIconWrapper',
})(({ theme }) => ({
  width: '48px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: theme.spacing(6),
}))

export const StyleReorderTextWrapper = styled('div', {
  name: 'BaseLayout',
  slot: 'ReorderTextWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  color: theme.palette.common.white,
  padding: theme.spacing(2, 0),
}))

export const StyledReorder = styled('div', {
  name: 'BaseLayout',
  slot: 'Reorder',
})(({ theme }) => ({
  backgroundColor: theme.palette.neutral.main,
  borderRadius: theme.spacing(4),
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(4),
}))

export const StyledReorderText = styled(Typography, {
  name: 'BaseLayout',
  slot: 'ReorderText',
})(() => ({
  width: '100%',
}))

export const StyledIconReturningCustomer2 = styled(IconReturningCustomerTwo, {
  name: 'BaseLayout',
  slot: 'IconReturningCustomer',
})(({ theme }) => ({
  fontSize: '48px',
  color: theme.palette.common.white,
}))
