import { useTranslation } from 'next-i18next'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'

export const GetCountriesLinks = () => {
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()

  return [
    {
      label: `${t('Footer.Labels.CountryList.be.label')}`,
      url: `https://www.visiondirect.be/${langCode.toLowerCase() === 'fr-be' ? 'fr/' : ''}`,
      isActive: `${t('Footer.Labels.CountryList.be.active')}`,
    },
    {
      label: `${t('Footer.Labels.CountryList.fr.label')}`,
      url: 'https://www.visiondirect.fr',
      isActive: `${t('Footer.Labels.CountryList.fr.active')}`,
    },
    {
      label: `${t('Footer.Labels.CountryList.ie.label')}`,
      url: 'https://www.visiondirect.ie',
      isActive: `${t('Footer.Labels.CountryList.ie.active')}`,
    },
    {
      label: `${t('Footer.Labels.CountryList.it.label')}`,
      url: 'https://www.visiondirect.it',
      isActive: `${t('Footer.Labels.CountryList.it.active')}`,
    },
    {
      label: `${t('Footer.Labels.CountryList.nl.label')}`,
      url: 'https://www.visiondirect.nl',
      isActive: `${t('Footer.Labels.CountryList.nl.active')}`,
    },
    {
      label: `${t('Footer.Labels.CountryList.es.label')}`,
      url: 'https://www.visiondirect.es',
      isActive: `${t('Footer.Labels.CountryList.es.active')}`,
    },
    {
      label: `${t('Footer.Labels.CountryList.uk.label')}`,
      url: 'https://www.visiondirect.co.uk',
      isActive: `${t('Footer.Labels.CountryList.uk.active')}`,
    },
  ]
}
