import { MenuItem, menuItemClasses as MuiMenuItemClasses } from '@mui/material'
import styled from '@mui/material/styles/styled'

export const StyledMenuItem = styled(MenuItem, {
  name: 'StyledMenuItem',
})(({ theme }) => ({
  [`&.${MuiMenuItemClasses.root}`]: {
    ...theme.typography.body2,
    padding: '0',
    minHeight: 'unset',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
  [`& .${MuiMenuItemClasses.selected}`]: {
    textDecoration: 'underline',
  },
}))
