import { styled } from '@mui/material'

export const StyledTrustMarkWrapper = styled('div', {
  name: 'PaymentMethod',
})(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(6),
  justifyContent: 'flex-end',
  gap: theme.spacing(4),
  ['svg']: {
    fontSize: 32,
  },

  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-start',
  },
}))
