import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgTwitter = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 25 24" {...other}>
      <path
        fill="currentColor"
        d="m5.037 5 5.79 7.723L5 19h1.312l5.102-5.496L15.538 19H20l-6.117-8.156L19.307 5h-1.311l-4.7 5.061L9.5 5zm1.929.964h2.05l9.054 12.072h-2.05z"
      />
    </SvgIcon>
  )
}
export default SvgTwitter
