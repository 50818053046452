import { REG_EX } from '../constants/common'
//Custom libraries
import { AddressFormData } from '@typesApp/form'

const addressUtil = {
  optionalAddressFields: ['addressLine2', 'phone1'],
  addressFormFields: [
    'firstName',
    'lastName',
    'addressLine1',
    'addressLine2',
    'city',
    'country',
    'state',
    'zipCode',
    'phone1',
    'nickName',
    'email1',
    'addressType',
  ],

  validateAddressForm: (formData: AddressFormData, edit?: boolean) => {
    const editVal = edit ? edit : false

    for (const key in formData) {
      if (!addressUtil.optionalAddressFields.includes(key)) {
        if (formData[key] !== undefined && formData[key].trim() === '') {
          return false
        }
      }
    }

    if (!addressUtil.validatePhoneNumber(formData.phone1)) {
      return false
    }
    if (!addressUtil.validateEmail(formData.email1)) {
      return false
    }

    if (!editVal && !addressUtil.validateNickName(formData.nickName)) {
      return false
    }

    return true
  },

  validatePhoneNumber: (phoneNumber?: string) => {
    const PHONE = REG_EX.PHONE
    return phoneNumber === undefined || phoneNumber.trim() === '' || PHONE.test(phoneNumber.trim())
  },

  validateEmail: (email?: string) => {
    const EMAIL = REG_EX.EMAIL
    return email === undefined || EMAIL.test(email)
  },

  validateNickName: (nickName?: string) => {
    const NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR = REG_EX.NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR
    return nickName === undefined || nickName.trim() === '' || NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR.test(nickName)
  },

  removeLeadingTrailingWhiteSpace: (formData: AddressFormData): AddressFormData => {
    const result: AddressFormData = {}
    // remove leading and trailing white space from all form input fields.
    if (formData !== undefined && formData !== null) {
      Object.keys(formData).forEach(key => {
        let value
        if (Array.isArray(formData[key])) {
          value = formData[key].map(val => {
            return val.toString().trim()
          })
        } else {
          value = !!formData[key] ? formData[key].trim() : ''
        }
        result[key] = value
      })
    }
    return result
  },

  removeIgnorableAddressFormFields: (formData: AddressFormData): AddressFormData => {
    const result: AddressFormData = { ...formData }
    for (const key in result) {
      if (!addressUtil.addressFormFields.includes(key)) {
        delete result[key]
      }
    }
    return result
  },

  getAddressNickName: (addressDataFields: string[]): string => {
    return addressDataFields.reduce((accumulator, currentValue, currentIndex) => {
      return `${accumulator}${currentIndex < addressDataFields.length - 2 ? `${currentValue}, ` : `${currentValue} `}`
    }, '')
  },

  concatAddress: (addressLineField: string[]): string => {
    return addressLineField.reduce((accumulator, currentValue) => {
      return `${accumulator}${currentValue}`
    }, '')
  },
  addressIsSame: (address1: AddressFormData, address2: AddressFormData): boolean => {
    try {
      const isSameAddressLine = address1?.addressLine === address2?.addressLine
      const isSameCityAndZipCode = address1?.cityAndZipCode === address2?.cityAndZipCode
      return isSameAddressLine && isSameCityAndZipCode
    } catch (e) {
      return false
    }
  },
}

export default addressUtil
