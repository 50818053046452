import { Typography } from '@mui/material'
import { locationOriginSelector } from '@redux/selectors/site'
import { ICMExternalPage } from '@typesApp/cmsPlacement/CMExternalPage'
import { replaceLocaleInUrl } from '@utils/locale'
import Link from '@components/UI/Link'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

type EverythingElseLinksProps = {
  links: ICMExternalPage[]
}

export const EverythingElseLinks = ({ links }: EverythingElseLinksProps) => {
  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)

  return (
    <ul className="bottom-footer-list">
      {links.map(({ formattedUrl, title }) => (
        <li key={title} className="bottom-footer-list__item">
          <Link
            className="bottom-footer-list__item-link"
            href={replaceLocaleInUrl({ isLocaleDomain, locationOrigin, locale, href: formattedUrl })}
            title={title}
          >
            <Typography variant="caption">{title}</Typography>
          </Link>
        </li>
      ))}
    </ul>
  )
}
