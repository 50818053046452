import React from 'react'
import {
  StyledLogo,
  StyledMobileLogo,
  StyledTopBarContainer,
  StyledTopBarPipe,
  StyledTopBarWrapper,
  StyledTopBarWrapperLeft,
  StyledTopBarWrapperRight,
} from './TopBar.style'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import PhoneWidget from '../PhoneWidget'
import NavigationIcon from '../NavigationBar/components/NavigationIcon'
import Link from '@components/UI/Link'
import Container from '@components/UI/Container'
import LanguageSelector from '../LanguageSelector'
import { useRouter } from 'next/router'
import { locationOriginSelector } from '@redux/selectors/site'
import { useSelector } from 'react-redux'
import { replaceLocaleInUrl } from '@utils/locale'

const TopBar: React.FC<{
  hasScrolled?: boolean
}> = ({ hasScrolled }) => {
  const { langCode } = useStoreIdentity()
  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)
  const homePageURL = replaceLocaleInUrl({
    isLocaleDomain,
    locationOrigin,
    locale,
    href: `/${langCode}`,
  })

  return (
    <StyledTopBarWrapper scrolled={hasScrolled} component={'section'}>
      <Container>
        <StyledTopBarContainer>
          <StyledTopBarWrapperLeft>
            <Link href={homePageURL}>
              <StyledLogo locale={langCode} />
              <StyledMobileLogo variant="white" />
            </Link>
          </StyledTopBarWrapperLeft>
          <StyledTopBarWrapperRight>
            <NavigationIcon />
            <StyledTopBarPipe />
            <PhoneWidget isDesktop />
            <LanguageSelector />
          </StyledTopBarWrapperRight>
        </StyledTopBarContainer>
      </Container>
    </StyledTopBarWrapper>
  )
}

export default TopBar
