import { useEffect } from 'react'
import Log from '../../services/Log'
import { executeOnce } from '../../utils/common'
import { SiteInfo } from '@redux/reducers'
import { BRAND, URL } from '@foundation/constants/genesys'
import { sendAnalyticEventRaw } from '@foundation/analytics/tealium/lib'

declare global {
  interface Window {
    Genesys: any
    sendData: () => void
  }
}

export const getTransformLocale = locale => {
  return locale.replace('_', '-').toLowerCase()
}

export const useGenesys = (site: SiteInfo) => {
  const loadScript = () => {
    const brand = BRAND
    const language = getTransformLocale(site.mySite?.locale)
    const country = site.mySite?.country.toUpperCase()
    // TODO: get timezone, environment and deploymentId from site xStoreConfig after its available
    const timezone = 'Europe/London'
    const environment = site.mySite?.xStoreCfg.B2C_VD.environmentId
    const deploymentId = site.mySite?.xStoreCfg.B2C_VD.deploymentId

    return executeOnce(() => {
      window.Genesys =
        window.Genesys ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          ;(window.Genesys.q = window.Genesys.q || []).push(arguments)
        }
      window.Genesys.t = 1 * new Date().getTime()
      window.Genesys.c = {
        environment: environment,
        deploymentId: deploymentId,
      }

      let script = document.createElement('script')
      script.async = true
      script.src = URL
      script.charset = 'utf-8'
      document.head.appendChild(script)

      window.sendData = function () {
        window.Genesys('command', 'Database.set', {
          messaging: {
            customAttributes: {
              webmsg_brandcode: brand,
              webmsg_country: country,
              webmsg_language: language,
              webmsg_timezone: timezone,
            },
          },
        })
      }

      window.Genesys('subscribe', 'Messenger.opened', function () {
        window.sendData()
        const tealiumPayload = {
          id: 'Click',
          data_element_id: 'ChatBot_Open',
          data_description: 'Messenger.opened',
          data_analytics_available_call: '1',
        }
        sendAnalyticEventRaw(tealiumPayload)
      })
    }, 'genesys-chat')()
  }

  useEffect(() => {
    if (site.mySite) {
      try {
        loadScript()
      } catch (e: any) {
        Log.error('[Genesys Chat] LOAD SCRIPT ERROR ', e.message)
      }
    }

    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const messengerDiv = document.getElementById('genesys-messenger')
          if (messengerDiv) {
            messengerDiv.setAttribute('data-element-id', 'ChatBot_Open')
            messengerDiv.setAttribute('data-analytics_available_call', '1')
            observer.disconnect()
            break
          }
        }
      }
    })

    observer.observe(document.body, { childList: true, subtree: true })

    return () => observer.disconnect()
  }, [site.mySite])
}
