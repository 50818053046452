import styled from '@mui/material/styles/styled'
import {
  IconFacebook,
  IconInstagram,
  IconLinkedin,
  IconPinterest,
  IconTiktok,
  IconTwitter,
  IconYoutube,
} from '@components/UI/Icons/VD/Social'

const StyledIconFacebook = styled(IconFacebook, {
  name: 'SocialLinks',
  slot: 'IconFacebook',
  shouldForwardProp: prop => prop !== 'size',
})<{ size: number }>(({ size = 32 }) => ({
  fontSize: size,
}))

const StyledIconInstagram = styled(IconInstagram, {
  name: 'SocialLinks',
  slot: 'IconInstagram',
  shouldForwardProp: prop => prop !== 'size',
})<{ size: number }>(({ size = 32 }) => ({
  fontSize: size,
}))

const StyledIconLinkedin = styled(IconLinkedin, {
  name: 'SocialLinks',
  slot: 'IconLinkedin',
  shouldForwardProp: prop => prop !== 'size',
})<{ size: number }>(({ size = 32 }) => ({
  fontSize: size,
}))

const StyledIconPinterest = styled(IconPinterest, {
  name: 'SocialLinks',
  slot: 'IconPinterest',
  shouldForwardProp: prop => prop !== 'size',
})<{ size: number }>(({ size = 32 }) => ({
  fontSize: size,
}))

const StyledIconTiktok = styled(IconTiktok, {
  name: 'SocialLinks',
  slot: 'IconTiktok',
  shouldForwardProp: prop => prop !== 'size',
})<{ size: number }>(({ size = 32 }) => ({
  fontSize: size,
}))

const StyledIconTwitter = styled(IconTwitter, {
  name: 'SocialLinks',
  slot: 'IconTwitter',
  shouldForwardProp: prop => prop !== 'size',
})<{ size: number }>(({ size = 32 }) => ({
  fontSize: size,
}))

const StyledIconYoutube = styled(IconYoutube, {
  name: 'SocialLinks',
  slot: 'IconYoutube',
  shouldForwardProp: prop => prop !== 'size',
})<{ size: number }>(({ size = 32 }) => ({
  fontSize: size,
}))

export {
  StyledIconFacebook,
  StyledIconInstagram,
  StyledIconLinkedin,
  StyledIconPinterest,
  StyledIconTiktok,
  StyledIconTwitter,
  StyledIconYoutube,
}
