export const localeSvgSuffixes = {
  IT: (
    <g>
      <path d="M189.023 23.636C189.54 23.636 189.928 24.0235 189.928 24.5401V24.9276C189.928 25.4442 189.54 25.8317 189.023 25.8317C188.507 25.8317 188.119 25.4442 188.119 24.9276V24.5401C188.055 24.0235 188.442 23.636 189.023 23.636Z" />
      <path d="M192.446 12.593C192.446 12.0763 192.834 11.818 193.286 11.818C193.802 11.818 194.19 12.1409 194.19 12.593V12.8513C194.19 13.3033 193.802 13.6262 193.286 13.6262C192.769 13.6262 192.446 13.3033 192.446 12.8513V12.593ZM192.575 16.4031C192.575 16.0157 192.898 15.6282 193.286 15.6282C193.738 15.6282 194.061 15.9511 194.061 16.4031V25.1213C194.061 25.5734 193.738 25.8963 193.35 25.8963C192.963 25.8963 192.575 25.5734 192.575 25.1213V16.4031Z" />
      <path d="M197.483 23.1194V17.0489H196.644C196.321 17.0489 195.998 16.726 195.998 16.4031C195.998 16.0157 196.321 15.7573 196.644 15.7573H197.483V13.3679C197.483 12.9804 197.806 12.5929 198.194 12.5929C198.581 12.5929 198.969 12.9158 198.969 13.3679V15.7573H201.552C201.939 15.7573 202.198 16.0802 202.198 16.4031C202.198 16.7906 201.875 17.0489 201.552 17.0489H198.969V22.9256C198.969 24.1526 199.614 24.6047 200.648 24.6047C201.164 24.6047 201.423 24.4755 201.552 24.4755C201.875 24.4755 202.198 24.7984 202.198 25.1213C202.198 25.3796 202.004 25.638 201.746 25.7025C201.294 25.8963 200.841 25.9609 200.26 25.9609C198.71 25.9609 197.483 25.1859 197.483 23.1194Z" />
    </g>
  ),
  GB: (
    <g>
      <path d="M189.226 23.6495C189.75 23.6495 190.138 24.0372 190.138 24.5606V24.9483C190.138 25.4717 189.75 25.8788 189.226 25.8788C188.682 25.8788 188.294 25.4717 188.294 24.9483V24.5606C188.294 24.0372 188.682 23.6495 189.226 23.6495Z" />
      <path d="M191.67 20.8236V20.7849C191.67 17.9545 193.882 15.5507 196.812 15.5507C198.48 15.5507 199.605 16.1904 200.498 17.0046C200.614 17.1016 200.731 17.2954 200.731 17.528C200.731 17.9351 200.401 18.2453 200.013 18.2453C199.819 18.2453 199.644 18.1678 199.528 18.0709C198.81 17.4117 197.995 16.8689 196.792 16.8689C194.755 16.8689 193.203 18.5943 193.203 20.7461V20.7849C193.203 22.9561 194.794 24.6814 196.889 24.6814C198.034 24.6814 198.946 24.158 199.683 23.4601C199.78 23.3632 199.916 23.2663 200.11 23.2663C200.478 23.2663 200.789 23.5958 200.789 23.9448C200.789 24.1192 200.711 24.2937 200.595 24.41C199.644 25.3599 198.5 26.0191 196.812 26.0191C193.882 26.0191 191.67 23.6346 191.67 20.8236Z" />
      <path d="M201.875 20.8236V20.7849C201.875 17.9545 204.087 15.5507 207.114 15.5507C210.121 15.5507 212.332 17.9158 212.332 20.7461V20.7849C212.332 23.6152 210.101 26.0191 207.075 26.0191C204.068 26.0191 201.875 23.654 201.875 20.8236ZM210.8 20.8236V20.7849C210.8 18.633 209.19 16.8689 207.075 16.8689C204.902 16.8689 203.408 18.633 203.408 20.7461V20.7849C203.408 22.9367 204.999 24.6814 207.114 24.6814C209.287 24.6814 210.8 22.9367 210.8 20.8236Z" />
      <path d="M214.777 23.6495C215.301 23.6495 215.689 24.0372 215.689 24.5606V24.9483C215.689 25.4717 215.301 25.8788 214.777 25.8788C214.234 25.8788 213.846 25.4717 213.846 24.9483V24.5606C213.846 24.0372 214.234 23.6495 214.777 23.6495Z" />
      <path d="M226.825 25.1447C226.825 25.5518 226.515 25.9007 226.088 25.9007C225.68 25.9007 225.35 25.5712 225.35 25.1447V24.0591C224.671 25.1447 223.643 26.0171 221.916 26.0171C219.491 26.0171 218.075 24.3886 218.075 22.0042V16.4404C218.075 16.0139 218.385 15.6844 218.812 15.6844C219.22 15.6844 219.569 16.0139 219.569 16.4404V21.6358C219.569 23.4969 220.578 24.66 222.343 24.66C224.051 24.66 225.35 23.4 225.35 21.5195V16.4404C225.35 16.0139 225.661 15.6844 226.088 15.6844C226.495 15.6844 226.825 16.0139 226.825 16.4404V25.1447Z" />
      <path d="M229.599 12.2842C229.599 11.8771 229.929 11.5281 230.337 11.5281C230.763 11.5281 231.093 11.8771 231.093 12.2842V21.4731L236.525 15.9481C236.7 15.7543 236.875 15.6573 237.108 15.6573C237.515 15.6573 237.787 15.9869 237.787 16.3552C237.787 16.5878 237.69 16.7429 237.496 16.9368L234.217 20.1548L237.806 24.6717C237.942 24.8462 238 24.9819 238 25.1758C238 25.5829 237.69 25.8737 237.282 25.8737C237.011 25.8737 236.836 25.7767 236.642 25.5247L233.169 21.1435L231.093 23.1984V25.1176C231.093 25.5441 230.783 25.8737 230.356 25.8737C229.929 25.8737 229.599 25.5441 229.599 25.1176V12.2842Z" />
    </g>
  ),
  IE: (
    <g>
      <path d="M189.023 23.636C189.54 23.636 189.928 24.0235 189.928 24.5401V24.9276C189.928 25.4442 189.54 25.8317 189.023 25.8317C188.507 25.8317 188.119 25.4442 188.119 24.9276V24.5401C188.055 24.0235 188.442 23.636 189.023 23.636Z" />
      <path d="M192.446 12.593C192.446 12.0763 192.834 11.818 193.286 11.818C193.802 11.818 194.19 12.1409 194.19 12.593V12.8513C194.19 13.3033 193.802 13.6262 193.286 13.6262C192.769 13.6262 192.446 13.3033 192.446 12.8513V12.593ZM192.575 16.4031C192.575 16.0157 192.898 15.6282 193.286 15.6282C193.738 15.6282 194.061 15.9511 194.061 16.4031V25.1213C194.061 25.5734 193.738 25.8963 193.35 25.8963C192.963 25.8963 192.575 25.5734 192.575 25.1213V16.4031Z" />
      <path d="M201.358 26.0254C198.581 26.0254 196.386 23.8943 196.386 20.7945V20.7299C196.386 17.8239 198.387 15.499 201.164 15.499C204.135 15.499 205.814 17.953 205.814 20.6008C205.814 20.9883 205.491 21.3112 205.104 21.3112H197.871C198.065 23.5069 199.614 24.6693 201.423 24.6693C202.65 24.6693 203.554 24.1526 204.329 23.5069C204.458 23.3777 204.587 23.3131 204.781 23.3131C205.168 23.3131 205.427 23.5714 205.427 23.9589C205.427 24.1526 205.362 24.2818 205.168 24.4755C204.2 25.3797 203.037 26.0254 201.358 26.0254ZM204.329 20.2133C204.2 18.4051 203.102 16.7906 201.164 16.7906C199.421 16.7906 198.129 18.2114 197.935 20.2133H204.329Z" />
    </g>
  ),
  FR: (
    <g>
      <path d="M189.023 23.636C189.54 23.636 189.928 24.0235 189.928 24.5401V24.9276C189.928 25.4442 189.54 25.8317 189.023 25.8317C188.507 25.8317 188.119 25.4442 188.119 24.9276V24.5401C188.055 24.0235 188.442 23.636 189.023 23.636Z" />
      <path d="M192.704 17.0489H191.865C191.477 17.0489 191.219 16.726 191.219 16.4031C191.219 16.0802 191.542 15.7573 191.865 15.7573H192.704V14.9178C192.704 13.7554 193.027 12.9159 193.544 12.3346C194.061 11.818 194.771 11.5597 195.675 11.5597C196.192 11.5597 196.515 11.6243 196.838 11.6888C197.096 11.7534 197.354 12.0117 197.354 12.3346C197.354 12.7221 197.031 12.9804 196.644 12.9804C196.386 12.9159 196.127 12.9159 195.804 12.9159C194.642 12.9159 194.061 13.6262 194.061 15.047V15.8865H196.644C197.031 15.8865 197.29 16.1448 197.29 16.5323C197.29 16.8552 196.967 17.1781 196.644 17.1781H194.061V25.1859C194.061 25.5734 193.738 25.9609 193.35 25.9609C192.963 25.9609 192.575 25.638 192.575 25.1859V17.0489H192.704Z" />
      <path d="M198.71 16.4031C198.71 16.0157 199.033 15.6282 199.421 15.6282C199.873 15.6282 200.196 15.9511 200.196 16.4031V18.2759C200.906 16.5969 202.391 15.6282 203.618 15.6282C204.07 15.6282 204.329 15.9511 204.329 16.4031C204.329 16.7906 204.07 17.1135 203.683 17.1781C201.746 17.4364 200.131 18.8571 200.131 21.7632V25.1859C200.131 25.5734 199.808 25.9609 199.421 25.9609C199.033 25.9609 198.646 25.638 198.646 25.1859V16.4031H198.71Z" />
    </g>
  ),
  NL: (
    <g>
      <path d="M189.023 23.636C189.54 23.636 189.928 24.0235 189.928 24.5401V24.9276C189.928 25.4442 189.54 25.8317 189.023 25.8317C188.507 25.8317 188.119 25.4442 188.119 24.9276V24.5401C188.055 24.0235 188.442 23.636 189.023 23.636Z" />
      <path d="M192.446 16.4031C192.446 16.0157 192.769 15.6282 193.157 15.6282C193.609 15.6282 193.932 15.9511 193.932 16.4031V17.501C194.577 16.4031 195.611 15.5636 197.354 15.5636C199.808 15.5636 201.164 17.1781 201.164 19.5675V25.1213C201.164 25.5734 200.841 25.8963 200.454 25.8963C200.002 25.8963 199.679 25.5734 199.679 25.1213V19.955C199.679 18.0822 198.646 16.9198 196.902 16.9198C195.159 16.9198 193.867 18.1468 193.867 20.0841V25.1859C193.867 25.638 193.544 25.9609 193.157 25.9609C192.769 25.9609 192.382 25.638 192.382 25.1859V16.4031H192.446Z" />
      <path d="M203.941 12.2701C203.941 11.8826 204.264 11.4951 204.652 11.4951C205.104 11.4951 205.427 11.818 205.427 12.2701V25.1213C205.427 25.5734 205.104 25.8963 204.716 25.8963C204.329 25.8963 203.941 25.5734 203.941 25.1213V12.2701Z" />
    </g>
  ),
  BE: (
    <g>
      <path d="M189.023 23.6356C189.54 23.6356 189.928 24.023 189.928 24.5397V24.9271C189.928 25.4438 189.54 25.8312 189.023 25.8312C188.507 25.8312 188.119 25.4438 188.119 24.9271V24.5397C188.055 24.023 188.442 23.6356 189.023 23.6356Z" />
      <path d="M192.446 12.2696C192.446 11.8176 192.769 11.4947 193.157 11.4947C193.609 11.4947 193.932 11.8176 193.932 12.2696V17.7588C194.771 16.5318 195.933 15.5631 197.742 15.5631C200.131 15.5631 202.521 17.4359 202.521 20.7295V20.7941C202.521 24.0876 200.131 26.025 197.742 26.025C195.869 26.025 194.706 24.9917 193.932 23.8939V25.1209C193.932 25.5084 193.609 25.8958 193.221 25.8958C192.834 25.8958 192.446 25.5729 192.446 25.1209V12.2696ZM201.035 20.7941C201.035 18.4046 199.421 16.8547 197.483 16.8547C195.611 16.8547 193.867 18.4046 193.867 20.7295V20.7941C193.867 23.1189 195.611 24.6688 197.483 24.6688C199.421 24.6688 201.035 23.2481 201.035 20.7941Z" />
      <path d="M209.108 26.025C206.331 26.025 204.135 23.8939 204.135 20.7941V20.7295C204.135 17.8234 206.137 15.4986 208.914 15.4986C211.885 15.4986 213.564 17.9526 213.564 20.6003C213.564 20.9878 213.241 21.3107 212.853 21.3107H205.62C205.814 23.5064 207.364 24.6688 209.172 24.6688C210.399 24.6688 211.303 24.1522 212.078 23.5064C212.207 23.3772 212.337 23.3127 212.53 23.3127C212.918 23.3127 213.176 23.571 213.176 23.9584C213.176 24.1522 213.112 24.2813 212.918 24.4751C211.949 25.3792 210.787 26.025 209.108 26.025ZM212.078 20.2129C211.949 18.4046 210.851 16.7902 208.914 16.7902C207.17 16.7902 205.879 18.2109 205.685 20.2129H212.078Z" />
    </g>
  ),
  ES: (
    <g>
      <path d="M189.023 23.6356C189.54 23.6356 189.928 24.0231 189.928 24.5398V24.9272C189.928 25.4439 189.54 25.8313 189.023 25.8313C188.507 25.8313 188.119 25.4439 188.119 24.9272V24.5398C188.055 24.0231 188.442 23.6356 189.023 23.6356Z" />
      <path d="M196.45 26.0251C193.673 26.0251 191.477 23.894 191.477 20.7942V20.7296C191.477 17.8235 193.479 15.4987 196.256 15.4987C199.227 15.4987 200.906 17.9527 200.906 20.6004C200.906 20.9879 200.583 21.3108 200.196 21.3108H192.963C193.157 23.5065 194.706 24.6689 196.515 24.6689C197.742 24.6689 198.646 24.1523 199.421 23.5065C199.55 23.3773 199.679 23.3127 199.873 23.3127C200.26 23.3127 200.519 23.5711 200.519 23.9585C200.519 24.1523 200.454 24.2814 200.26 24.4752C199.292 25.3793 198.129 26.0251 196.45 26.0251ZM199.421 20.2129C199.292 18.4047 198.194 16.7902 196.256 16.7902C194.513 16.7902 193.221 18.211 193.027 20.2129H199.421Z" />
      <path d="M202.391 24.7335C202.262 24.6689 202.133 24.4106 202.133 24.2169C202.133 23.894 202.391 23.5711 202.779 23.5711C202.908 23.5711 203.037 23.6356 203.166 23.7002C204.2 24.4106 205.233 24.7335 206.331 24.7335C207.558 24.7335 208.397 24.0877 208.397 23.119V23.0544C208.397 22.0212 207.235 21.6337 205.879 21.3108C204.329 20.8587 202.585 20.3421 202.585 18.4693V18.4047C202.585 16.6611 204.006 15.5632 206.008 15.5632C207.106 15.5632 208.268 15.8861 209.237 16.4028C209.431 16.5319 209.624 16.7257 209.624 17.0486C209.624 17.3715 209.301 17.6944 208.978 17.6944C208.849 17.6944 208.72 17.6298 208.656 17.6298C207.816 17.1131 206.912 16.8548 206.072 16.8548C204.91 16.8548 204.135 17.5006 204.135 18.2756V18.3401C204.135 19.3088 205.362 19.6317 206.718 20.0838C208.268 20.5358 209.947 21.1171 209.947 22.9253V22.9899C209.947 24.8626 208.397 25.9605 206.395 25.9605C204.975 25.9605 203.489 25.5084 202.391 24.7335Z" />
    </g>
  ),
}
