import config from '@configs/index'

export const TrustPilot = () => {
  return (
    <>
      {config?.trustPilotEnabled && (
        <script
          defer
          type="text/javascript"
          src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>
      )}
    </>
  )
}

export default TrustPilot
