import { StyledLoader } from '@components/UI/Loader'
import styles from './styles/index.module.scss'

export const FullPageLoader: React.FC = () => {
  return (
    <div className={styles['full-page-loader']}>
      <StyledLoader size={84} />
    </div>
  )
}
