import { FC } from 'react'
import { useWebsitePhoneNumber } from '@hooks/useWebsitePhoneNumber'
import { Typography } from '@mui/material'
import { IconTelephone } from '@components/UI/Icons/VD/General'
import styles from './PhoneWidget.module.scss'
import Link from '@components/UI/Link'
import { useTranslation } from 'next-i18next'

interface IPhoneWidgetProps {
  isDesktop: boolean
}

const PhoneWidget: FC<IPhoneWidgetProps> = ({ isDesktop }) => {
  const phoneNumber = useWebsitePhoneNumber()
  const { t } = useTranslation()
  const contactLink = t('Header.ContactLink')

  return isDesktop ? (
    <Link className={styles['phone-widget-wrapper-desktop']} href={contactLink}>
      <IconTelephone />
      <Typography variant="subtitle2">{phoneNumber}</Typography>
    </Link>
  ) : (
    <a href={`tel:${phoneNumber}`} data-element-id="MainNav_Phone" className={styles['phone-widget-wrapper-mobile']}>
      <IconTelephone />
      <Typography variant="subtitle2">{phoneNumber}</Typography>
    </a>
  )
}

export default PhoneWidget
