import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgThuiswinkelNl = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 32 28" {...other}>
      <path
        fill="#1E1E1E"
        d="M16 17.598 2.56 9.839 16 2.08 29.44 9.84zm-.87 8.563H1.69V11.32l13.44 7.758zm15.164 0h-13.44v-7.083l13.44-7.758zM16 .117 0 9.357v18.526h32V9.356z"
      />
      <path fill="#BDEB18" d="m30.294 11.32-13.44 7.758v7.083h13.44z" />
      <path fill="#FC3F4D" d="M1.706 26.16h13.425v-7.082L1.706 11.32z" />
      <path fill="#fff" d="M2.56 9.84 16 17.597l13.424-7.759L16 2.081z" />
    </SvgIcon>
  )
}
export default SvgThuiswinkelNl
