import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgSelloConfianzaOnlineEs = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 32 32" {...other}>
      <path
        fill="#fff"
        d="M16 31.488c8.554 0 15.488-6.934 15.488-15.488S24.554.512 16 .512.512 7.446.512 16 7.446 31.488 16 31.488"
      />
      <path
        fill="#684A98"
        fillRule="evenodd"
        d="M24.796 23.874h.455l-.227-.531zm.14-1.498h.176l.999 2.174h-.556l-.127-.26h-.809l-.12.26h-.569zm-3.129.449v-.43h1.58l.032.07-.923 1.655h.866v.43h-1.63l-.038-.07.947-1.655zm-1.245-.43h.506v2.155l-.114.031-1.232-1.13v1.099h-.506v-2.155l.12-.032 1.226 1.125zm-9.712 0 .12-.032 1.226 1.125v-1.093h.505v2.155l-.113.031-1.239-1.13v1.099h-.505v-2.155zm-1.239.72a.6.6 0 0 0-.227-.227.61.61 0 0 0-.822.227.7.7 0 0 0-.088.36q.002.21.088.36.085.144.228.222a.54.54 0 0 0 .297.082.57.57 0 0 0 .297-.082.6.6 0 0 0 .227-.221.7.7 0 0 0 .089-.36.76.76 0 0 0-.089-.36m-1.598.784a1.1 1.1 0 0 1-.082-.43q-.002-.23.082-.43c.057-.139.132-.252.233-.36.102-.1.228-.183.367-.24q.21-.087.468-.088.257.001.467.088a1.09 1.09 0 0 1 .689 1.03 1.08 1.08 0 0 1-.322.79l-.02.019a1.2 1.2 0 0 1-.347.228 1.2 1.2 0 0 1-.467.088c-.171 0-.329-.032-.468-.088a1 1 0 0 1-.348-.228l-.019-.019a1.1 1.1 0 0 1-.233-.36m9.529-1.523h.177l.998 2.174h-.556l-.126-.26h-.81l-.12.26h-.568zm.31 1.498-.228-.531-.227.53zm-2.357-1.48H16v2.156h-.505zm-.74 1.284h-.67v.872h-.505v-2.155h1.358v.43h-.853v.423h.67zm-8.96-1.024c.107-.101.227-.17.366-.221q.211-.074.43-.082c.152 0 .284.025.398.057.114.037.202.082.278.132.07.05.126.101.164.14l-.31.366a.5.5 0 0 0-.1-.089 1 1 0 0 0-.177-.107.6.6 0 0 0-.253-.044.591.591 0 0 0-.537.322.65.65 0 0 0-.082.329q.001.178.07.328a.54.54 0 0 0 .214.247.65.65 0 0 0 .575.056.7.7 0 0 0 .177-.094.6.6 0 0 0 .114-.101l.31.328c-.013.02-.032.032-.045.05-.032.032-.076.07-.12.108a1 1 0 0 1-.284.145 1.3 1.3 0 0 1-.411.064 1.16 1.16 0 0 1-.575-.146 1.5 1.5 0 0 1-.228-.17 1 1 0 0 1-.183-.228 1.13 1.13 0 0 1-.152-.587q-.002-.255.102-.462a.9.9 0 0 1 .259-.341"
        clipRule="evenodd"
      />
      <path
        fill="#859EAF"
        fillRule="evenodd"
        d="m20.284 25.371.468-.006v2.458l-.107.032-1.48-1.492v1.46h-.467v-2.458l.108-.032 1.472 1.492V25.37zm2.9.405H22.13v.606h.873v.398h-.872v.651h1.093v.398h-1.567v-2.458h1.529zm-6.786 1.655v.398H14.92v-2.451h.468v2.053zm.809-2.06h.474v2.458h-.474zm-6.711.777a.8.8 0 0 0-.297-.296.75.75 0 0 0-.777 0 .8.8 0 0 0-.29.296.87.87 0 0 0-.115.456.9.9 0 0 0 .114.454.8.8 0 0 0 .683.399.74.74 0 0 0 .385-.108.8.8 0 0 0 .297-.29.87.87 0 0 0 .114-.456.87.87 0 0 0-.114-.455m-.013-.65a1.261 1.261 0 0 1 .632 1.106 1.26 1.26 0 0 1-.632 1.105c-.196.114-.423.17-.676.177a1.33 1.33 0 0 1-.676-.177 1.2 1.2 0 0 1-.455-.461 1.25 1.25 0 0 1-.164-.645q.002-.35.164-.644c.114-.19.266-.341.455-.461q.295-.171.676-.171c.26 0 .48.057.676.17m1.485-.133.108-.032 1.472 1.492v-1.46l.468-.006v2.458l-.108.031-1.478-1.491v1.46h-.468v-2.452z"
        clipRule="evenodd"
      />
      <path
        fill="#684A98"
        d="M16.354 20.038h-.19c-2.085-.044-3.892-.815-5.377-2.288-1.523-1.503-2.294-3.355-2.294-5.504 0-2.135.77-3.987 2.294-5.503 1.485-1.466 3.292-2.231 5.371-2.275h.013a.638.638 0 0 1 .012 1.276c-1.77.038-3.241.657-4.499 1.902C10.395 8.93 9.77 10.433 9.77 12.24c0 1.814.626 3.318 1.915 4.594 1.258 1.251 2.73 1.877 4.493 1.915h.17c1.827 0 3.343-.626 4.633-1.915 1.301-1.276 1.933-2.78 1.933-4.594v-.076c0-.354.285-.638.638-.638s.639.284.639.638v.076c0 2.142-.778 3.994-2.313 5.504-1.51 1.523-3.375 2.294-5.523 2.294"
      />
      <path
        fill="#C8D521"
        d="M16 32c-4.398 0-8.202-1.573-11.311-4.689C1.579 24.202 0 20.398 0 16S1.573 7.798 4.689 4.689C7.798 1.579 11.602 0 16 0s8.202 1.573 11.311 4.689C30.421 7.798 32 11.602 32 16s-1.573 8.202-4.689 11.311C24.21 30.421 20.398 32 16 32m0-30.976c-4.114 0-7.678 1.478-10.59 4.385C2.495 8.322 1.023 11.88 1.023 16c0 4.114 1.478 7.678 4.385 10.59 2.913 2.914 6.47 4.386 10.591 4.386 4.114 0 7.678-1.478 10.59-4.385 2.914-2.913 4.386-6.47 4.386-10.591 0-4.114-1.478-7.678-4.385-10.59C23.684 2.495 20.12 1.023 16 1.023"
      />
      <path
        fill="#C8D521"
        d="M16.095 17.858h-.032c-1.422-.07-2.66-.62-3.678-1.643-1.086-1.087-1.643-2.42-1.643-3.962s.55-2.875 1.643-3.969c1.024-1.017 2.256-1.567 3.678-1.63q.153-.007.297-.006c1.542 0 2.869.55 3.962 1.643 1.068 1.068 1.624 2.376 1.643 3.88a.64.64 0 0 1-.632.65h-.012a.64.64 0 0 1-.639-.631c-.019-1.176-.436-2.155-1.27-2.996-.853-.853-1.851-1.264-3.058-1.264q-.116-.002-.221.007h-.013c-1.106.05-2.035.461-2.837 1.257-.853.853-1.264 1.858-1.264 3.065s.417 2.205 1.264 3.059c.802.802 1.725 1.22 2.837 1.27a.637.637 0 0 1 .607.67.63.63 0 0 1-.632.6"
      />
      <path
        fill="#859EAF"
        d="M16.348 15.62a3.28 3.28 0 0 1-2.383-.985 3.26 3.26 0 0 1-.985-2.382c0-.923.328-1.725.985-2.383a3.26 3.26 0 0 1 2.383-.985 3.26 3.26 0 0 1 2.382.985c.657.658.986 1.46.986 2.383 0 .929-.335 1.725-.992 2.382a3.23 3.23 0 0 1-2.376.986m0-5.453c-.582 0-1.068.203-1.48.613-.41.411-.606.898-.606 1.48 0 .58.202 1.06.613 1.471s.898.613 1.479.613a2 2 0 0 0 1.472-.613 1.98 1.98 0 0 0 .613-1.478c0-.582-.202-1.068-.613-1.479a2 2 0 0 0-1.478-.607"
      />
    </SvgIcon>
  )
}
export default SvgSelloConfianzaOnlineEs
