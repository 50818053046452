import { ArrowDownIcon } from '@components/UI/Icons/arrows'
import { Typography } from '@mui/material'
import { ICMArticle } from '@typesApp/cmsPlacement/CMArticle'
import { ChangeEvent, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import {
  StyledBottomFooter,
  StyledBottomFooterAddress,
  StyledBottomFooterInner,
  StyledItemContent,
  StyledFooterAccordion,
  StyledFooterAccordionSummary,
  StyledMobileOrTabletWrapper,
  StyledDesktopWrapper,
} from './BottomFooter.style'
import { GetCountriesLinks } from './components/CountriesLinks'
import { GetSocialLinks } from './components/SocialLinks'
import Container from '@components/UI/Container'
import Log from '@services/Log'
import useReplaceLocaleInCMSUrl from '@hooks/useReplaceLocaleInCMSUrl'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { ICMExternalPage } from '@typesApp/cmsPlacement/CMExternalPage'
import { EverythingElseLinks } from './components/EverythingElseLinks'

type BottomFooterProps = {
  addressComponent: ICMArticle
  everythingElseLinks: IPlacement<ICMExternalPage> | undefined
}

const BottomFooter = ({ addressComponent, everythingElseLinks }: BottomFooterProps) => {
  const { t } = useTranslation()
  const replaceLocaleInCmsUrls = useReplaceLocaleInCMSUrl()
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const menuItemsAccordionDetailsRef = useRef<HTMLDivElement | null>(null)
  const versionNumber: string | undefined = process.env.NEXT_PUBLIC_RELEASE_VERSION

  const handleChangeEvent = (_: ChangeEvent<{}>, expanded: boolean) => {
    setIsExpanded(v => !v)
    if (expanded) {
      menuItemsAccordionDetailsRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  // TODO: Replace dummy data with CoreMedia API call
  const socialLinks = GetSocialLinks()
  const countryLinks = GetCountriesLinks()

  const CountriesList = () => (
    <ul className="bottom-footer-list">
      {countryLinks.map(link => (
        <li key={link.label} className="bottom-footer-list__item">
          <a
            className={`bottom-footer-list__item-link ${
              link.isActive === 'true' ? 'bottom-footer-list__item-link--active' : ''
            }`}
            href={link.url}
            title={link.label}
          >
            <Typography variant="caption">{link.label}</Typography>
          </a>
        </li>
      ))}
    </ul>
  )

  const SocialList = () => (
    <ul className="bottom-footer-list bottom-footer-list--social">
      {socialLinks.map(social => (
        <li key={social.label} className="bottom-footer-list__item">
          <a href={social.url} target="_blank" rel="noreferrer" title={social.label}>
            {social.icon}
          </a>
        </li>
      ))}
    </ul>
  )

  if (!versionNumber) {
    Log.info('No version information present at build time.')
  }

  const copyRight = (
    <div className="bottom-footer-copyright">
      <Typography variant="caption">{t('Footer.Labels.Copyright', { year: new Date().getFullYear() })}</Typography>
    </div>
  )

  const renderFooterAddress = (
    <div className="bottom-footer-container">
      <SocialList />
      {addressComponent && (
        <div
          className="bottom-footer-address"
          dangerouslySetInnerHTML={{ __html: replaceLocaleInCmsUrls(addressComponent?.detailText) }}
        />
      )}
      {copyRight}
    </div>
  )

  return (
    <>
      <StyledMobileOrTabletWrapper>
        <Container>
          <div>
            <StyledFooterAccordion onChange={handleChangeEvent} expanded={isExpanded}>
              <StyledFooterAccordionSummary className="bottom-footer-header" expandIcon={<ArrowDownIcon />}>
                <span className="bottom-footer-title">{t('Footer.Labels.EverythingElse')}</span>
              </StyledFooterAccordionSummary>
              <StyledItemContent className="bottom-footer-content" ref={menuItemsAccordionDetailsRef}>
                <div className="bottom-footer-container">
                  <EverythingElseLinks links={everythingElseLinks?.items || []} />
                  <CountriesList />
                </div>
              </StyledItemContent>
            </StyledFooterAccordion>
          </div>
        </Container>
        <StyledBottomFooter>
          <StyledBottomFooterAddress>
            <div>{renderFooterAddress}</div>
          </StyledBottomFooterAddress>
        </StyledBottomFooter>
      </StyledMobileOrTabletWrapper>

      <StyledDesktopWrapper>
        <Container>
          <StyledBottomFooterInner>
            <div>
              <div className="bottom-footer-container">
                <EverythingElseLinks links={everythingElseLinks?.items || []} />
                <CountriesList />
              </div>
              <div className="bottom-footer-container">
                <SocialList />
                {copyRight}
              </div>
            </div>
          </StyledBottomFooterInner>
        </Container>
      </StyledDesktopWrapper>
    </>
  )
}

export default BottomFooter
