import config from '@configs/index'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import React, { useMemo } from 'react'
import {
  StyledLanguageSelectorButton,
  StyledLanguageSelectorMenu,
  StyledLanguageSelectorMenuItem,
} from './LanguageSelector.style'
import { ArrowDownIcon } from '@components/UI/Icons/arrows'
import { hostnameSelector } from '@redux/selectors/site'
import { useSelector } from 'react-redux'
import Link from '@components/UI/Link'
import { getSublocaleMap } from './helper'
import { useRouter } from 'next/router'
import { getClientLocale } from '@utils/locale'

export type SublocaleMap = {
  [key: string]: {
    subLocales: string[]
  }
}

const LanguageSelector: React.FC = () => {
  const { isLocaleDomain } = useRouter()
  const { langCode } = useStoreIdentity()
  const [currentSubLocale, currentMainLocale] = langCode.split('-')
  const hostname = useSelector(hostnameSelector)
  let protocol = ''
  if (typeof window !== 'undefined') {
    protocol = window.location.protocol.replace(':', '://')
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isLanguageSelectorOpen = Boolean(anchorEl)

  const handleLanguageSelectorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleLanguageSelectorClose = () => {
    setAnchorEl(null)
  }

  const supportedLocales = useMemo(() => config.availableLocales || [], [])
  const sublocaleMap = useMemo(() => getSublocaleMap(supportedLocales), [supportedLocales])
  const currentSublocalesForMainLocale = useMemo(
    () => sublocaleMap[currentMainLocale]?.subLocales || [],
    [currentMainLocale, sublocaleMap]
  )

  const subLocaleOptions = useMemo(
    () =>
      currentSublocalesForMainLocale.map(subLocalOption => ({
        label: subLocalOption.toUpperCase(),
        value: `${subLocalOption}-${currentMainLocale}`,
        href: `${protocol}${hostname}/${getClientLocale(isLocaleDomain, subLocalOption + '-' + currentMainLocale)}`,
        isActive: subLocalOption === currentSubLocale,
      })),
    [currentSublocalesForMainLocale, currentMainLocale, currentSubLocale, hostname, protocol]
  )

  if (subLocaleOptions.length <= 1) {
    return null
  }

  return (
    <>
      <StyledLanguageSelectorButton
        menuOpen={isLanguageSelectorOpen}
        variant="primary"
        onClick={handleLanguageSelectorClick}
      >
        {currentSubLocale.toUpperCase()} <ArrowDownIcon />
      </StyledLanguageSelectorButton>
      <StyledLanguageSelectorMenu
        open={isLanguageSelectorOpen}
        onClose={handleLanguageSelectorClose}
        anchorEl={anchorEl}
      >
        {subLocaleOptions.map(({ label, isActive, value, href }) => (
          <StyledLanguageSelectorMenuItem key={label} value={value} selected={isActive}>
            <a href={href}>{label}</a>
          </StyledLanguageSelectorMenuItem>
        ))}
      </StyledLanguageSelectorMenu>
    </>
  )
}

export default LanguageSelector
