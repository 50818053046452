import { SiteInfo } from '@redux/reducers'
import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation'
import AnalyticsTealium from './AnalyticsTealium'
import Forter from './Forter'
import Monetate from './Monetate'
import TrustPilot from './TrustPilot'
import GenesysChat from './GenesysChat'
import WordLift from './WordLift'
import DirectBilling from './DirectBilling'

export enum Scripts {
  Analytics,
  Tealium,
  Forter,
  Monetate,
  TrustPilot,
  Genesys,
  Wordlift,
}

export const isScriptEnabled = (query: ReadonlyURLSearchParams, scriptName: string): boolean => {
  const scripts = Object.keys(Scripts)
  let includedScripts: string[] = []
  query?.forEach((value, key) => {
    scripts.forEach(script => {
      const regexObj = new RegExp(`disable${script}`)
      if (regexObj.test(key) && value === 'true') {
        includedScripts = [...includedScripts, script]
      }
    })
  })
  return !includedScripts.some(script => script === scriptName)
}

interface ExternalScriptsProps {
  mySite?: SiteInfo
  trustPilot?: boolean
  wordlift?: boolean
}

export const ExternalScripts: React.FC<ExternalScriptsProps> = ({ mySite, trustPilot = false }) => {
  const query = useSearchParams()
  const includeAnalytics = isScriptEnabled(query, 'Analytics')
  const includeTealium = isScriptEnabled(query, 'Tealium')
  const includeForter = isScriptEnabled(query, 'Forter')
  const includeMonetate = isScriptEnabled(query, 'Monetate')
  const includeTrustPilot = isScriptEnabled(query, 'TrustPilot')
  const includeWordlift = isScriptEnabled(query, 'WordLift')
  const includeLiveChat = isScriptEnabled(query, 'LiveChat') && mySite?.xStoreCfg?.CHAT_BOT_ENABLED
  const includeDirectBilling = mySite?.xStoreCfg?.DIRECT_BILLING_ENABLED

  // Temporary fix for testing
  const isBE = mySite?.locale === 'nl_BE' || mySite?.locale === 'fr_BE'
  const isNL = mySite?.locale === 'nl_NL'

  return (
    <>
      {!trustPilot ? (
        <>
          {includeLiveChat && <GenesysChat mySite={mySite} />}
          {includeWordlift && <WordLift mySite={mySite} />}
          <AnalyticsTealium
            mySite={mySite as SiteInfo}
            enableAnalytics={includeAnalytics}
            enableTealium={includeTealium}
          />
          {includeForter && <Forter mySite={mySite} />}
          {includeMonetate && <Monetate mySite={mySite} />}
          {includeDirectBilling && <DirectBilling mySite={mySite} />}
          {/* Temporary fix for testing */}
          {isBE && (
            <script
              type="text/javascript"
              src="https://js-cdn.dynatrace.com/jstag/1658b155923/bf81760wsn/79c7151e1d766ea8_complete.js"
              crossOrigin="anonymous"
            ></script>
          )}
          {isNL && (
            <script
              type="text/javascript"
              src="https://js-cdn.dynatrace.com/jstag/1658b155923/bf81760wsn/6736e6125a9b80e2_complete.js"
              crossOrigin="anonymous"
            ></script>
          )}
        </>
      ) : (
        <>{includeTrustPilot && <TrustPilot />}</>
      )}
    </>
  )
}

export default ExternalScripts
