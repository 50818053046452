import { useTranslation } from 'next-i18next'
import {
  StyledIconFacebook,
  StyledIconInstagram,
  StyledIconLinkedin,
  StyledIconPinterest,
  StyledIconTiktok,
  StyledIconTwitter,
  StyledIconYoutube,
} from './SocialLinks.style'

import { useSite } from '@foundation/hooks/useSite'

export const GetSocialLinks = () => {
  const { mySite } = useSite()
  const { t } = useTranslation()
  const locales = ['en_uk', 'en_ie']
  const socialLinksOrder = [
    {
      locale: 'en_uk',
      order: ['facebook', 'instagram', 'tiktok', 'twitter', 'youtube', 'linkedin', 'pinterest'],
    },
    {
      locale: 'en_ie',
      order: ['facebook', 'instagram', 'tiktok', 'twitter', 'youtube', 'linkedin', 'pinterest'],
    },
  ]
  function getOrderByLocale(locale) {
    const localeConfig = socialLinksOrder.find(config => config.locale === locale)
    return localeConfig?.order || []
  }

  const order = getOrderByLocale(mySite.locale.toLowerCase())
  let socialLinks = [
    {
      label: 'Facebook',
      url: t('Footer.SocialLinks.facebook'),
      icon: <StyledIconFacebook size={32} />,
    },
    {
      label: 'Instagram',
      url: t('Footer.SocialLinks.instagram'),
      icon: <StyledIconInstagram size={32} />,
    },
    {
      label: 'Twitter',
      url: t('Footer.SocialLinks.twitter'),
      icon: <StyledIconTwitter size={32} />,
    },
  ]

  if (locales.includes(mySite.locale.toLocaleLowerCase())) {
    socialLinks = [
      ...socialLinks,
      {
        label: 'TikTok',
        url: t('Footer.SocialLinks.tiktok'),
        icon: <StyledIconTiktok size={32} />,
      },
      {
        label: 'YouTube',
        url: t('Footer.SocialLinks.youtube'),
        icon: <StyledIconYoutube size={32} />,
      },
      {
        label: 'Linkedin',
        url: t('Footer.SocialLinks.linkedin'),
        icon: <StyledIconLinkedin size={32} />,
      },
      {
        label: 'Pinterest',
        url: t('Footer.SocialLinks.pinterest'),
        icon: <StyledIconPinterest size={32} />,
      },
    ]
  }
  const sortedSocialLinks = socialLinks.sort(
    (a, b) => order.indexOf(a.label.toLowerCase()) - order.indexOf(b.label.toLowerCase())
  )

  return sortedSocialLinks
}
