import { useEffect } from 'react'
import Log from '@services/Log'
import { executeOnce } from '@utils/common'
import config from '@configs/config.base'
import { SiteInfo } from '@redux/reducers/reducerStateInterface'

const SCRIPT_ID = 'directBillingLoader'

export const useDirectBilling = (site: SiteInfo) => {
  const isDirectBillingEnabled = site?.mySite?.xStoreCfg?.DIRECT_BILLING_ENABLED

  const loadScript = () => {
    return executeOnce(() => {
      const host = config.directBilling.host
      const url = `${host}${host.endsWith('/') ? '' : '/'}static/js/loader.js`

      const script = document.createElement('script')
      script.id = SCRIPT_ID
      script.type = 'text/javascript'
      script.async = true
      script.src = url

      document.body.appendChild(script)
    }, 'directbilling')()
  }

  useEffect(() => {
    if (isDirectBillingEnabled) {
      try {
        loadScript()
      } catch (e: any) {
        Log.error('[DIRECTBILLING] LOAD SCRIPT ERROR ', e.message)
      }
    }
  }, [isDirectBillingEnabled])
}
