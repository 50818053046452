import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IPlacement } from '@typesApp/cmsPlacement/Placement'
//components
import { hamburgerMenuOpenSelector, openDrawerSearchSelector } from '@features/ui/selector'
//queries
import { INVENTORY } from '@constants/common'
import { CART, CHECKOUT, SIGNIN } from '@constants/routes'
import { currentContractIdSelector } from '@features/contract/selector'
import { orderApi } from '@features/order/query'
import { setOpenDrawerSearch } from '@features/ui/action'
import { loginStatusSelector, wcTokenSelector } from '@features/user/selector'
import { fetchWishlistAction } from '@features/wishList/action'
import { wishlistEnabledSelector } from '@features/wishList/selector'
import { useSite } from '@foundation/hooks/useSite'
import useScrollingUp from '@hooks/useScrollingUp/useScrollingUp'
import { useAbandonedOrder } from '@pages_views/Cart/useAbandonedOrder'
import { AppState } from '@redux/store'
import { CartPayload } from '@typesApp/cart'
import Axios, { Canceler } from 'axios'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { usePathname } from 'next/navigation'
import { BenefitContainer, HamburgerMenuGreyBackground, StyledHeaderOuter, WrapperHeader } from './Header.style'
import { Seo } from './components/Seo'
import TopBar from './components/TopBar'
import { usePageType } from '@foundation/hooks/usePageType'
import { setMonetatePageType } from '@foundation/monetate/lib'
import { listenMonetateQ } from '@foundation/monetate/useMonetate'
import { useBreakpoint } from '@hooks/useBreakpoint'

const SHOW_BENEFIT_BAR = false

const BenefitBar = SHOW_BENEFIT_BAR ? dynamic(() => import('./components/BenefitBar')) : () => null
const PromotionBar = dynamic(() => import('./components/PromotionBar'))

const NavigationBar = dynamic(() => import('./components/NavigationBar'))

const HamburgerMenu = dynamic(() => import('./components/HamburgerMenu'), {
  ssr: false,
})
const KeySellingBar = dynamic(() => import('../../components/KeySellingBar'))
const SearchHeader = dynamic(() => import('./components/SearchHeader'), {
  ssr: false,
})

const contentsHeader = (headerPlacements: IPlacement[]) => {
  const benefitBar = headerPlacements.find(placement => placement.name === 'header_benefit_bar')
  const trendingNow = headerPlacements.find(placement => placement.name === 'header_search_trending_now')
  const searchBanner = headerPlacements.find(placement => placement.name === 'header_search_banner')
  const promoBar = headerPlacements.find(placement => placement.name === 'header_promo_bar')
  const findStore = headerPlacements.find(placement => placement.name === 'header_find_a_store')
  const keySellingBar = headerPlacements.find(placement => placement.name === 'header_key_selling_bar')

  return {
    benefitBar,
    trendingNow,
    searchBanner,
    promoBar,
    findStore,
    keySellingBar,
  }
}

const Header: React.FC = () => {
  const dispatch = useDispatch()
  const { mySite } = useSite()
  const header = useSelector((s: AppState) => s.cms.header)

  const { benefitBar, promoBar, keySellingBar, findStore } = contentsHeader(header?.headerPlacements ?? [])

  const extraCSS = header?.extraCSS || []
  const extraJS = header?.extraJS || []
  const isLoggedIn = useSelector(loginStatusSelector)
  const { isMobile } = useBreakpoint()
  const isHamburgerDrawerOpen = useSelector(hamburgerMenuOpenSelector)
  const isSearchDrawerOpen = useSelector(openDrawerSearchSelector)
  const wcToken = useSelector(wcTokenSelector)
  const isWishlistEnabled = useSelector(wishlistEnabledSelector)
  const [cancels] = useState<Canceler[]>([])
  const contractId = useSelector(currentContractIdSelector)
  const scrolled = useScrollingUp()
  const router = useRouter()
  const pathname = usePathname()
  const { pageType } = usePageType()
  const isCartLoadRequired = pathname.includes(CART) || pathname.includes(CHECKOUT)

  const [height, setHeight] = useState(0)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight)
    }
  }, [])

  const timestampRef = React.useRef(Date.now()).current
  useAbandonedOrder()

  const [getCart] = orderApi.endpoints.getCart.useLazyQuery()

  const defaultCurrencyID = useMemo<string>(() => mySite.defaultCurrencyID || '', [mySite])

  const checkInventory = useMemo<boolean>(() => mySite.inventorySystem === INVENTORY.NON_ATP, [mySite.inventorySystem])

  const payloadBase: CartPayload = {
    storeId: mySite.storeID,
    currency: defaultCurrencyID,
    contractId,
    checkInventory,
    cancelToken: new Axios.CancelToken(c => cancels.push(c)),
  }
  useEffect(() => {
    if (mySite && isWishlistEnabled && !!wcToken) {
      dispatch(fetchWishlistAction(mySite.storeID))
    }
  }, [isWishlistEnabled, mySite, wcToken, dispatch])

  useEffect(() => {
    if (!isCartLoadRequired && mySite && wcToken) {
      getCart({
        ...payloadBase,
        fetchCatentries: true,
        fetchShippingInfo: false,
        refetch: false,
        sessionId: timestampRef,
        updateProducts: true,
      })
    }
  }, [isCartLoadRequired, isLoggedIn])

  const [isHeaderVisible, setHeaderVisibility] = useState<boolean>(true)
  const monetateLoaded = listenMonetateQ()

  useEffect(() => {
    const shouldBeVisible = scrolled === null || isSearchDrawerOpen
    setHeaderVisibility(shouldBeVisible)
  }, [scrolled, isSearchDrawerOpen])

  useEffect(() => {
    dispatch(setOpenDrawerSearch(false))
  }, [router.asPath])

  useEffect(() => {
    if (pageType && pageType.length > 0 && monetateLoaded) {
      setMonetatePageType(pageType)
    }
  }, [pageType, monetateLoaded])

  const renderHeader = (isSticky: boolean) => {
    return (
      <WrapperHeader isSticky={isSticky} className={clsx({ 'is-scrolled': !isHeaderVisible })} ref={ref}>
        {SHOW_BENEFIT_BAR ? (
          <BenefitContainer>
            <BenefitBar hasScrolled={scrolled!} data={benefitBar!} findStore={findStore} />
          </BenefitContainer>
        ) : null}
        <div className="navbar-animation-wrapper">
          <TopBar hasScrolled={scrolled!} />
          <NavigationBar isSticky={!isHeaderVisible} />
        </div>
        <HamburgerMenu open={isHamburgerDrawerOpen} />
        <div className="promobar-animation-wrapper">{promoBar && <PromotionBar data={promoBar} />}</div>
        <SearchHeader isHeaderVisible={!!scrolled} />
      </WrapperHeader>
    )
  }

  return (
    <>
      <Seo extraJS={extraJS} extraCSS={extraCSS} />
      {isHamburgerDrawerOpen && <HamburgerMenuGreyBackground />}
      {isMobile ? (
        <StyledHeaderOuter isSticky={false} height={height}>
          {renderHeader(false)}
        </StyledHeaderOuter>
      ) : (
        renderHeader(true)
      )}
      {keySellingBar && (
        <div className="key-selling-bar-animation-wrapper">{<KeySellingBar items={keySellingBar.items} />}</div>
      )}
    </>
  )
}

export default Header
