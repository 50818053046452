import { Button } from '@components/UI/Button'
import MenuItem from '@components/UI/MenuItem'
import { Menu, styled, paperClasses as MuiPaperClasses, buttonClasses as MuiButtonClasses } from '@mui/material'

export const StyledLanguageSelectorButton = styled(Button, {
  name: 'LanguageSelectorButton',
})<{
  menuOpen: boolean
}>(({ theme, menuOpen }) => ({
  marginLeft: theme.spacing(12.5),
  minWidth: 'auto',
  color: theme.palette.common.white,

  [`&.${MuiButtonClasses.root}`]: {
    padding: 0,

    ['&:hover, &:focus']: {
      backgroundColor: 'transparent',
    },
  },

  svg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
    transform: menuOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.2s ease-in-out',
  },

  [theme.breakpoints.up('md')]: {
    color: theme.palette.primary.tones[0],
    marginLeft: theme.spacing(3),

    [`&.${MuiButtonClasses.root}`]: {
      padding: theme.spacing(4, 8),
    },
  },
}))

export const StyledLanguageSelectorMenu = styled(Menu, {
  name: 'StyledLanguageSelectorMenu',
})(() => ({
  [`& .${MuiPaperClasses.root}`]: {
    minWidth: 100,
  },
}))

export const StyledLanguageSelectorMenuItem = styled(MenuItem, {
  name: 'StyledLanguageSelectorMenuItem',
})(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  a: {
    textAlign: 'center',
    width: '100%',
    ...theme.typography.body1,
  },
}))
