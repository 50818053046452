import { FC } from 'react'
import { useSite } from '@foundation/hooks/useSite'
import Link from '../Link'
import { getTrustMarkIconByName, getTrustMarkLinkByName } from './helper'
import { StyledTrustMarkWrapper } from './TrustmartIcons.style'

const TrustmarkIcons: FC = () => {
  const { mySite } = useSite()
  const localeCode = mySite?.locale?.split('_')?.[0]
  const trustMarkIconsConfig = mySite.xStoreCfg?.trustmark_icon?.[localeCode]
  const trustMarkIconNames = trustMarkIconsConfig ? trustMarkIconsConfig.split('|') : []

  return trustMarkIconNames ? (
    <StyledTrustMarkWrapper>
      {trustMarkIconNames?.map(iconName => {
        const Icon = getTrustMarkIconByName(iconName)
        const trustmarkLink = getTrustMarkLinkByName(iconName)
        const shouldOpenInNewTab = trustmarkLink?.includes('https')

        return Icon ? (
          <Link
            aria-label={iconName}
            data-description={iconName}
            href={trustmarkLink}
            key={trustmarkLink}
            target={shouldOpenInNewTab ? '_blank' : '_self'}
          >
            <Icon width={32} onClick={e => e.stopPropagation()} />
          </Link>
        ) : null
      })}
    </StyledTrustMarkWrapper>
  ) : null
}

export default TrustmarkIcons
