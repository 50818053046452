import { sessionErrorSelector } from '@features/error/selector'
import { RootReducerState } from '@redux/reducers'
import { defaultStates } from '@redux/reducers/initStates'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { resetErrorSuccessAction, resetSessionPopupLogonErrorAction } from '../slice'

export const resetError = createAsyncThunk('error/resetError', async (_, { dispatch, getState }) => {
  const state = getState() as RootReducerState
  const sessionErrorObject = await sessionErrorSelector(state)
  if (!sessionErrorObject.errorKey && !sessionErrorObject.errorCode) {
    //do not reset session error
    const sessionError = { ...defaultStates.error }
    dispatch(resetErrorSuccessAction(sessionError))
  } else {
    dispatch(resetSessionPopupLogonErrorAction())
  }
})
