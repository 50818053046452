import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { ArrowDownIcon } from '@components/UI/Icons/arrows'
import Log from '../../../../services/Log'
import { ICMArticle } from '../../../../types/cmsPlacement/CMArticle'
import {
  StyledFooterContactWrapper,
  StyledFooterContactHeading,
  StyledFooterContactBody,
  StyledFooterContactItem,
  StyledDesktopWrapper,
} from '../FooterContact/FooterContact.style'
import {
  StyledFooterAccordionSummary,
  StyledFooterLinkHeading,
  StyledMobileOrTabletWrapper,
} from '../../../../components/UI/FooterLinks/FooterLinks.style'
import useReplaceLocaleInCMSUrl from '@hooks/useReplaceLocaleInCMSUrl'

type FooterContactProps = {
  id: string
  items: ICMArticle[]
}

function FooterContact(props: FooterContactProps) {
  const { id, items } = props

  const { t } = useTranslation()
  const replaceLocaleInCmsUrls = useReplaceLocaleInCMSUrl()
  const [expandedId, setExpandedId] = useState<string>('')

  if (!items) {
    Log.info('No items provided to FooterContact')
    return null
  }

  const handleOnChange = (accordeonId: string) => {
    setExpandedId(expandedId === accordeonId ? '' : accordeonId)
  }

  return (
    <StyledFooterContactWrapper className="footer-contact">
      <StyledMobileOrTabletWrapper onChange={() => handleOnChange(id)} expanded={expandedId === id}>
        <StyledFooterAccordionSummary expandIcon={<ArrowDownIcon />} itemID={id}>
          <StyledFooterLinkHeading variant="button">
            {t('FooterContact.labels.CustomerService')}
          </StyledFooterLinkHeading>
        </StyledFooterAccordionSummary>
        {items?.map(item => (
          <StyledFooterContactBody key={item.id}>
            <StyledFooterContactHeading>{item.title}</StyledFooterContactHeading>
            <StyledFooterContactItem dangerouslySetInnerHTML={{ __html: replaceLocaleInCmsUrls(item?.detailText) }} />
          </StyledFooterContactBody>
        ))}
      </StyledMobileOrTabletWrapper>
      {items?.map(item => (
        <StyledDesktopWrapper key={item.id}>
          <div>
            <StyledFooterLinkHeading key={item.id} variant="button">
              {item.title}
            </StyledFooterLinkHeading>
            <StyledFooterContactBody>
              <StyledFooterContactItem dangerouslySetInnerHTML={{ __html: replaceLocaleInCmsUrls(item?.detailText) }} />
            </StyledFooterContactBody>
          </div>
        </StyledDesktopWrapper>
      ))}
    </StyledFooterContactWrapper>
  )
}

export default FooterContact
