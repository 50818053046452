import styled from '@mui/material/styles/styled'
import Link from '@mui/material/Link'
import { TextField } from '@components/UI/TextField'
import { inputBaseClasses as MuiInputBaseClasses } from '@mui/material'

export const StyledNewsletterContainer = styled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: 375,
  },
}))

export const StyledCloseWrapper = styled('div', {
  name: 'NewsletterClose',
})(({ theme }) => ({
  color: theme.palette.neutral.tones[100],
  display: 'flex',
}))

export const StyledHeader = styled('div', {
  name: 'NewsletterHeader',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(8),
  color: theme.palette.neutral.tones[100],
  backgroundColor: theme.palette.background.neutral,
}))

export const StyledNewsletterDescriptionWrapper = styled('div', {
  name: 'NewsletterDescription',
})(({ theme }) => ({
  padding: theme.spacing(12, 8),
}))

export const StyledNewsletterTypographyTitle = styled('div', {
  name: 'NewsletterTitle',
})(({ theme }) => ({
  padding: theme.spacing(0, 0, 15),
}))

export const StyledNewsletterPrivacyText = styled('div', {
  name: 'NewsletterPrivacy',
})(({ theme }) => ({
  padding: theme.spacing(12, 0),
  color: theme.palette.neutral.light,

  a: {
    color: 'inherit',
    textDecoration: 'none',
  },
}))

export const StyledThankYouContainer = styled('div', {
  name: 'NewsletterThankYou',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledPromoCodeTextField = styled('div', {
  name: 'PromoCode',
  slot: 'TextField',
})(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  border: `solid 1px ${theme.palette.text.dark.secondary}`,
  justifyContent: 'space-between',
  alignItems: 'baseline',
}))

export const StyledPromoCodeSpan = styled('span', {
  name: 'PromoCode',
  slot: 'Span',
})(() => ({
  display: 'flex',
  alignSelf: 'flex-start',
  fontSize: 14,
  fontWeight: 600,
  textDecoration: 'underline',
}))

export const StyledIconSpan = styled('span', {
  name: 'Icon',
  slot: 'Span',
})(() => ({
  display: 'flex',
  alignSelf: 'flex-end',
  cursor: 'pointer',
}))

export const StyledIconLink = styled(Link, {
  name: 'Icon',
  slot: 'Link',
})(() => ({
  cursor: 'pointer',
}))

export const StyledNewsLetterRow = styled('div', {
  name: 'NewsletterRow',
})(({ theme }) => ({
  padding: theme.spacing(12, 8, 0),
}))

export const StyledFormButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(16),
  alignItems: 'center',
}))

export const StyledNewsletterSignupSuccess = styled('div', {
  name: 'NewsletterSuccess',
})(({ theme }) => ({
  color: theme.palette.neutral.light,
}))

export const StyledNewsLetterInput = styled(TextField, {
  name: 'NewsletterInput',
})(({ theme }) => ({
  [`& .${MuiInputBaseClasses.root}`]: {
    ...theme.typography.body1,
    height: 'auto',
    padding: theme.spacing(4.75, 8),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 8),
    },
  },

  [`& .${MuiInputBaseClasses.input}`]: {
    height: 'auto',
    padding: theme.spacing(0),
  },

  [`& .${MuiInputBaseClasses.inputAdornedStart}`]: {
    paddingLeft: theme.spacing(0),
  },
}))
