import { SublocaleMap } from './LanguageSelector'

export const getSublocaleMap = (supportedLocales: string[]): SublocaleMap => {
  return supportedLocales.reduce((acc, locale) => {
    const [subLocale, mainLocale] = locale.split('-')
    acc[mainLocale] = acc[mainLocale] || { subLocales: [] }
    if (subLocale && !acc[mainLocale].subLocales.includes(subLocale)) {
      acc[mainLocale].subLocales.push(subLocale)
    }

    return acc
  }, {})
}
