import { ICMChild } from '@typesApp/cmsPlacement/Children'
import { CMS_PLACEMENTS } from '../constants/placements'
import { controlTeaserTarget } from '../features/cms/utils'
import { ICMChannel, ICMExternalPage } from '../types/cmsPlacement/CMExternalPage'

export type NavigationItem = {
  id: string
  title: string
  url?: string
  children: NavigationItem[]
}

export const formatNavigationLinks = (items: ICMChannel[] | ICMExternalPage[]): NavigationItem[] => {
  return items?.map((item: ICMChannel | ICMExternalPage): NavigationItem => {
    const title = item.title
    const id = item.id || ''
    const children =
      item?.children?.map((c: ICMChild) => {
        const id = c.name
        const title = c.title || ''
        const url = c.type === CMS_PLACEMENTS.CM_TEASER ? controlTeaserTarget(c.teaserTargets) : c.formattedUrl || ''

        const navigationItem: NavigationItem = {
          id,
          title,
          url,
          children: [],
        }

        return navigationItem
      }) || []

    return {
      id,
      title,
      children,
    }
  })
}
