import config from '@configs/index'
import styles from './Logo.module.css'
import { localeSvgSuffixes } from './LogoSuffixes'

const supportedLocales = config.availableLocales || []

const supportedLocaleMap = {
  'be-be': 'BE',
  'es-es': 'ES',
  'fr-fr': 'FR',
  'fr-be': 'BE',
  'en-ie': 'IE',
  'it-it': 'IT',
  'nl-nl': 'NL',
  'nl-be': 'BE',
  'en-uk': 'GB',
}

export type LogoProps = {
  locale?: (typeof supportedLocales)[number]
  variant?: 'white'
  className?: string
}

function Logo(props: LogoProps) {
  const { locale, variant, className } = props
  const mappedLocale = locale ? supportedLocaleMap[locale] : null
  const SvgSuffix = mappedLocale ? localeSvgSuffixes[mappedLocale] : null

  return (
    <div className={`${styles.logoWrapper} ${className}`} data-style={variant}>
      <svg width="238" height="33" viewBox="0 0 238 33" xmlns="http://www.w3.org/2000/svg">
        <path
          className="logo-symbol-top"
          d="M13.6908 0H19.4384C21.5695 0 23.3131 1.74364 23.3131 3.87476V8.84736C23.0548 8.78278 22.7965 8.78278 22.4736 8.91194C18.2759 10.4618 21.6341 9.23483 17.4364 10.7847C15.8219 11.366 16.726 13.82 18.3405 13.2387L20.2779 12.5284C18.0176 20.2779 10.6556 24.0881 3.81018 23.184C3.55186 23.1194 3.29354 23.1194 2.97065 23.0548C2.64775 22.9902 2.19569 22.8611 2.00196 22.7965C1.80822 22.7319 1.5499 22.6027 1.35616 22.409C0.516634 21.6986 0 20.6008 0 19.4384V13.6908C0 11.5597 1.74364 9.81605 3.87476 9.81605H9.75147V3.87476C9.75147 1.74364 11.5597 0 13.6908 0Z"
        />
        <path
          className="logo-symbol-bottom"
          d="M22.7319 13.3033L23.5714 15.5636C24.2172 17.1781 26.6067 16.274 26.0254 14.6595L24.1526 9.68689H29.1252C31.2564 9.68689 33 11.4305 33 13.5616V19.3738C33 21.5049 31.2564 23.2485 29.1252 23.2485H23.3131V29.1252C23.3131 31.2564 21.5695 33 19.4384 33H13.6262C11.4951 33 9.75147 31.2564 9.75147 29.1252V25.3796C15.5636 24.1526 20.7299 20.0841 22.7319 13.3033Z"
        />
        <path d="M46.8845 25.1213L39.9745 9.23483C39.91 9.10567 39.8454 8.97651 39.8454 8.78278C39.8454 8.26614 40.2974 7.81409 40.8787 7.81409C41.3953 7.81409 41.7182 8.13698 41.9119 8.58904L48.1115 23.3777L54.3111 8.52446C54.5049 8.13698 54.8278 7.81409 55.2798 7.81409C55.861 7.81409 56.2485 8.26614 56.2485 8.78278C56.2485 8.91193 56.2485 9.04109 56.1839 9.17025L49.3385 25.1213C49.0802 25.638 48.7573 25.9609 48.1761 25.9609H48.047C47.4657 25.9609 47.0783 25.638 46.8845 25.1213Z" />
        <path d="M58.7025 8.45987C58.7025 7.81408 59.2192 7.42661 59.8649 7.42661C60.5107 7.42661 61.0274 7.81408 61.0274 8.45987V8.78277C61.0274 9.36398 60.5107 9.81604 59.8649 9.81604C59.2192 9.81604 58.7025 9.36398 58.7025 8.78277V8.45987ZM58.8317 13.4325C58.8317 12.9158 59.2837 12.4638 59.8004 12.4638C60.3816 12.4638 60.7691 12.9158 60.7691 13.4325V24.9276C60.7691 25.5088 60.3816 25.8963 59.8004 25.8963C59.2192 25.8963 58.8317 25.4442 58.8317 24.9276V13.4325Z" />
        <path d="M64.0626 24.3464C63.8689 24.2172 63.6751 23.9589 63.6751 23.636C63.6751 23.1839 64.0626 22.7965 64.5147 22.7965C64.7084 22.7965 64.9021 22.8611 65.0313 22.9256C66.3875 23.8297 67.8082 24.3464 69.2289 24.3464C70.8434 24.3464 71.9413 23.5068 71.9413 22.2798V22.2153C71.9413 20.8591 70.3914 20.407 68.6477 19.8904C66.5812 19.3092 64.2563 18.5988 64.2563 16.1448C64.2563 13.8845 66.1291 12.3346 68.7769 12.3346C70.1976 12.3346 71.683 12.7867 72.9745 13.4325C73.2329 13.5616 73.4912 13.8845 73.4912 14.272C73.4912 14.7241 73.1037 15.1115 72.6516 15.1115C72.4579 15.1115 72.3287 15.047 72.1996 14.9824C71.1017 14.3366 69.8747 13.9491 68.7769 13.9491C67.227 13.9491 66.1937 14.7886 66.1937 15.8865V15.9511C66.1937 17.1781 67.8082 17.6947 69.6164 18.2113C71.683 18.7926 73.8787 19.6321 73.8787 22.0215V22.0861C73.8787 24.6047 71.8121 26.0254 69.1644 26.0254C67.3561 26.0254 65.4833 25.3796 64.0626 24.3464Z" />
        <path d="M76.8493 8.45987C76.8493 7.81408 77.3659 7.42661 78.0117 7.42661C78.6575 7.42661 79.1742 7.81408 79.1742 8.45987V8.78277C79.1742 9.36398 78.6575 9.81604 78.0117 9.81604C77.3659 9.81604 76.8493 9.36398 76.8493 8.78277V8.45987ZM76.9785 13.4325C76.9785 12.9158 77.4305 12.4638 77.9472 12.4638C78.5284 12.4638 78.9158 12.9158 78.9158 13.4325V24.9276C78.9158 25.5088 78.5284 25.8963 77.9472 25.8963C77.3659 25.8963 76.9785 25.4442 76.9785 24.9276V13.4325Z" />
        <path d="M82.0802 19.2446C82.0802 15.4344 84.9863 12.2701 88.9902 12.2701C92.9295 12.2701 95.8356 15.3699 95.8356 19.1155V19.18C95.8356 22.9256 92.9295 26.09 88.9256 26.09C84.9863 26.09 82.0802 22.9902 82.0802 19.2446ZM93.8336 19.2446C93.8336 16.3386 91.7025 14.0137 88.9256 14.0137C86.0841 14.0137 84.0822 16.3386 84.0822 19.1155V19.18C84.0822 22.0215 86.1487 24.2818 88.9902 24.2818C91.8317 24.3464 93.8336 22.0215 93.8336 19.2446Z" />
        <path d="M98.7417 13.4325C98.7417 12.9159 99.1937 12.4638 99.7103 12.4638C100.292 12.4638 100.679 12.9159 100.679 13.4325V14.8532C101.519 13.4325 102.939 12.2701 105.2 12.2701C108.364 12.2701 110.237 14.4012 110.237 17.5656V24.863C110.237 25.4442 109.785 25.8317 109.268 25.8317C108.687 25.8317 108.299 25.3797 108.299 24.863V18.0822C108.299 15.6282 106.943 14.0783 104.618 14.0783C102.358 14.0783 100.679 15.7573 100.679 18.2114V24.9276C100.679 25.5088 100.292 25.8963 99.7103 25.8963C99.1291 25.8963 98.7417 25.4442 98.7417 24.9276V13.4325Z" />
        <path d="M113.789 9.88061C113.789 8.78277 114.628 7.94324 115.726 7.94324H120.699C126.317 7.94324 130.192 11.818 130.192 16.8552V16.9198C130.192 21.9569 126.317 25.8317 120.699 25.8317H115.726C114.628 25.8317 113.789 24.9922 113.789 23.8943V9.88061ZM120.763 22.2153C123.992 22.2153 126.123 20.0196 126.123 16.8552V16.7906C126.123 13.6262 123.928 11.4305 120.763 11.4305H117.728V22.2153H120.763Z" />
        <path d="M132.646 8.84736C132.646 7.74951 133.614 7.03914 134.841 7.03914C136.068 7.03914 137.037 7.74951 137.037 8.84736V8.91194C137.037 10.0098 136.068 10.7202 134.841 10.7202C133.614 10.7202 132.646 10.0098 132.646 8.84736ZM132.904 13.8845C132.904 12.7867 133.744 11.9472 134.841 11.9472C135.939 11.9472 136.779 12.7867 136.779 13.8845V24.0235C136.779 25.1213 135.939 25.9609 134.841 25.9609C133.744 25.9609 132.904 25.1213 132.904 24.0235V13.8845Z" />
        <path d="M139.814 13.8845C139.814 12.7867 140.654 11.9471 141.751 11.9471C142.849 11.9471 143.689 12.7867 143.689 13.8845V14.4657C144.27 13.1096 145.174 11.8826 146.337 11.8826C147.564 11.8826 148.209 12.6575 148.209 13.7554C148.209 14.7886 147.564 15.3698 146.724 15.5636C144.787 16.0156 143.689 17.501 143.689 20.2779V23.8943C143.689 24.9922 142.849 25.8317 141.751 25.8317C140.654 25.8317 139.814 24.9922 139.814 23.8943V13.8845Z" />
        <path d="M155.894 26.09C151.761 26.09 148.726 23.184 148.726 18.9863V18.9217C148.726 15.047 151.503 11.818 155.507 11.818C160.092 11.818 162.094 15.5636 162.094 18.4051C162.094 19.5029 161.319 20.2779 160.286 20.2779H152.601C152.988 22.0215 154.215 22.9902 155.959 22.9902C157.057 22.9902 157.896 22.6673 158.736 22.0861C158.994 21.8924 159.252 21.7632 159.704 21.7632C160.544 21.7632 161.19 22.409 161.19 23.3131C161.19 23.8297 160.932 24.2172 160.673 24.4755C159.446 25.4442 157.896 26.09 155.894 26.09ZM158.413 17.8885C158.155 16.1448 157.186 14.9824 155.507 14.9824C153.892 14.9824 152.859 16.1448 152.536 17.8885H158.413Z" />
        <path d="M163.773 18.9863C163.773 15.047 166.744 11.818 170.941 11.818C173.008 11.818 174.493 12.3992 175.591 13.3679C175.849 13.5616 176.172 14.0137 176.172 14.7241C176.172 15.6928 175.397 16.4677 174.429 16.4677C173.912 16.4677 173.524 16.274 173.331 16.0802C172.62 15.5636 171.91 15.2407 170.877 15.2407C168.939 15.2407 167.583 16.9198 167.583 18.9863V19.0509C167.583 21.1174 168.939 22.8611 171.07 22.8611C172.104 22.8611 172.879 22.4736 173.654 21.8924C173.847 21.7632 174.235 21.5049 174.687 21.5049C175.591 21.5049 176.301 22.2153 176.301 23.184C176.301 23.7006 176.043 24.0881 175.72 24.411C174.558 25.4442 173.137 26.1546 170.877 26.1546C166.808 26.09 163.773 22.9256 163.773 18.9863Z" />
        <path d="M179.014 21.8924V15.4344H178.885C177.98 15.4344 177.205 14.7241 177.205 13.7554C177.205 12.7867 177.916 12.0763 178.885 12.0763H179.014V10.3972C179.014 9.2994 179.853 8.45987 180.951 8.45987C182.049 8.45987 182.888 9.2994 182.888 10.3972V12.0763H184.567C185.472 12.0763 186.247 12.7867 186.247 13.7554C186.247 14.7241 185.536 15.4344 184.567 15.4344H182.888V21.3111C182.888 22.2153 183.276 22.6673 184.115 22.6673C184.245 22.6673 184.503 22.6673 184.567 22.6673C185.407 22.6673 186.182 23.3777 186.182 24.2818C186.182 24.9922 185.73 25.5088 185.213 25.7671C184.503 26.0254 183.857 26.1546 183.018 26.1546C180.628 26.0254 179.014 25.0567 179.014 21.8924Z" />
        {SvgSuffix}
      </svg>
    </div>
  )
}
export default Logo
