import { Typography } from '@mui/material'
import styled from '@mui/material/styles/styled'

export const StyledFooterContactWrapper = styled('div', {
  name: 'FooterContactWrapper',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(1, minmax(0, 1fr)), [linename] 100px',
  lineHeight: theme.spacing(2),

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gap: theme.spacing(16),
    paddingTop: theme.spacing(16),
    paddingBottom: theme.spacing(6),
    '& div:nth-of-type(2)': {
      gridColumn: '2 / span 1',
    },
  },
}))

export const StyledFooterContactHeading = styled(Typography, {
  name: 'FooterContactHeading',
})(({ theme }) => ({
  ...theme.typography.button,
  textTransform: 'uppercase',
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(4),
}))

export const StyledFooterContactBody = styled('div', {
  name: 'FooterContactBody',
})(({ theme }) => ({
  whiteSpace: 'nowrap',

  a: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}))

export const StyledFooterContactItem = styled('div', {
  name: 'FooterContactItem',
})(({ theme }) => ({
  p: {
    ...theme.typography.body2,
  },
}))

export const StyledDesktopWrapper = styled('div', {
  name: 'FooterContactDesktopWrapper',
})(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}))
