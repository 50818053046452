import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgInstagram = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 24 24" {...other}>
      <g fill="currentColor" clipPath="url(#instagram_svg__clip0_278_1645)">
        <path d="M12 5.472c2.112 0 2.368 0 3.264.064.768.064 1.216.192 1.472.256.384.128.64.32.896.576.256.256.448.576.576.896.128.256.256.704.256 1.472.064.832.064 1.088.064 3.264 0 2.176 0 2.368-.064 3.264-.064.768-.192 1.216-.256 1.472-.128.384-.32.64-.576.896a2.685 2.685 0 0 1-.896.576c-.256.128-.704.256-1.472.256-.832.064-1.088.064-3.264.064-2.176 0-2.368 0-3.264-.064-.768-.064-1.216-.192-1.472-.256-.384-.128-.64-.32-.896-.576a2.685 2.685 0 0 1-.576-.896c-.128-.256-.256-.704-.256-1.472-.064-.832-.064-1.088-.064-3.264 0-2.176 0-2.368.064-3.264.064-.768.192-1.216.256-1.472.128-.384.32-.64.576-.896.256-.256.512-.448.896-.576.256-.128.704-.256 1.472-.256.896-.064 1.152-.064 3.264-.064ZM12 4c-2.176 0-2.432 0-3.328.064-.832 0-1.408.128-1.92.384a3.75 3.75 0 0 0-1.408.896 3.75 3.75 0 0 0-.896 1.408c-.256.512-.384 1.088-.384 1.92C4 9.568 4 9.824 4 12s0 2.432.064 3.328c.064.832.192 1.408.384 1.92s.448.96.896 1.408a3.75 3.75 0 0 0 1.408.896c.512.192 1.088.32 1.92.384C9.568 20 9.824 20 12 20s2.432 0 3.328-.064c.832-.064 1.408-.192 1.92-.384a3.75 3.75 0 0 0 1.408-.896 3.75 3.75 0 0 0 .896-1.408c.192-.512.32-1.088.384-1.92C20 14.496 20 14.176 20 12s0-2.432-.064-3.328c-.064-.832-.192-1.408-.384-1.92a3.75 3.75 0 0 0-.896-1.408 3.75 3.75 0 0 0-1.408-.896c-.512-.192-1.088-.32-1.92-.384C14.432 4 14.176 4 12 4Z" />
        <path d="M12 7.904C9.76 7.904 7.904 9.76 7.904 12S9.76 16.096 12 16.096 16.096 14.24 16.096 12 14.24 7.904 12 7.904Zm0 6.784A2.704 2.704 0 0 1 9.312 12 2.704 2.704 0 0 1 12 9.312 2.704 2.704 0 0 1 14.688 12 2.704 2.704 0 0 1 12 14.688ZM16.288 8.672a.96.96 0 1 0 0-1.92.96.96 0 0 0 0 1.92Z" />
      </g>
      <defs>
        <clipPath id="instagram_svg__clip0_278_1645">
          <path fill="#fff" d="M4 4h16v16H4z" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
export default SvgInstagram
