import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgPostNl = (props: SvgIconProps) => {
  const { ...other } = props || {}
  return (
    <SvgIcon fill="none" viewBox="0 0 36 34" {...other}>
      <g clipPath="url(#post_NL_svg__clip0_13187_15760)">
        <path
          fill="#A6ACB2"
          d="M8.424.365c4.085 0 10.665 2.587 15.022 4.992 4.81 2.678 11.573 8.078 11.573 11.8 0 3.948-6.944 9.213-11.573 11.8-4.22 2.314-10.665 5.037-14.886 5.037q-1.702 0-2.723-.544C2.524 31.589.981 23.692.981 17.157c0-3.313.408-6.808 1.18-9.712.908-3.45 2.178-5.719 3.676-6.581.635-.318 1.498-.5 2.587-.5"
        />
        <path
          fill="url(#post_NL_svg__paint0_radial_13187_15760)"
          d="M33.884 17.247c0-2.813-5.31-7.76-10.983-10.892C16.322 2.77 8.878.682 6.427 1.998c-2.768 1.543-4.311 9.259-4.311 15.25 0 6.035 1.452 13.66 4.311 15.248 2.632 1.453 9.758-.68 16.43-4.402 5.808-3.131 11.027-7.851 11.027-10.847"
        />
        <path
          fill="#fff"
          d="m26.442 20.107-.046-2.86c0-.635-.227-.907-.726-.907a1 1 0 0 0-.59.181c-.227.137-.363.227-.454.273l-.045.045v3.222s0 .046-.046.046h-1.27s-.046 0-.046-.046v-4.538q0-.136.136-.136h1.135s.045 0 .045.045v.363s0 .046.046.046h.045l.046-.046c.136-.09.363-.227.499-.272.317-.136.635-.181.907-.181 1.044 0 1.589.59 1.589 1.724v3.04s0 .046-.046.046zm2.36 0q-.069 0 0 0l-.046-6.99c0-.045 0-.045.046-.045.09 0 .771.454.998.681.181.182.272.454.272.68v5.583s0 .045-.045.045H28.8"
        />
        <path
          fill="#383792"
          d="M14.869 17.747c0 1.68-1.09 2.45-2.451 2.45-1.362 0-2.451-.77-2.451-2.45s1.09-2.45 2.45-2.45c1.362 0 2.451.77 2.451 2.45m-1.362 0c0-.908-.454-1.27-1.044-1.27s-1.044.408-1.044 1.27c0 .817.454 1.27 1.044 1.27.59.046 1.044-.408 1.044-1.27m7.76-3.63c0-.046 0-.046-.045-.046-.09 0-.817.454-.998.68a1 1 0 0 0-.273.681v3.041c0 1.316.772 1.725 1.589 1.725.454 0 .771-.046.953-.182.045 0 .09-.045.09-.136v-.862c0-.046 0-.046-.045-.046s-.363.136-.544.136c-.409 0-.681-.181-.681-.862v-1.724c0-.046 0-.046.045-.046h1.135c.045 0 .045 0 .045-.045v-.908q0-.136-.136-.136h-1.044c-.045 0-.045 0-.045-.045zM4.885 15.567c0-.09.09-.136.136-.136h1.77c1.77 0 2.678 1.044 2.678 2.406 0 1.361-.999 2.314-2.678 2.314h-.544c-.046 0-.046 0-.046.046v2.223c0 .046 0 .046-.045.046-.091 0-.817-.454-.999-.68a1 1 0 0 1-.272-.682zm3.268 2.179c0-.59-.363-1.135-1.362-1.135h-.544c-.046 0-.046 0-.046.046v2.178c0 .046 0 .046.046.046h.544c1.18 0 1.362-.817 1.362-1.135m10.529-.182c-.273-.181-.636-.272-.953-.317-.046 0-.273-.046-.318-.091-.408-.09-.726-.182-.726-.409s.227-.363.544-.363c.409 0 .999.091 1.634.318.046 0 .091 0 .091-.045v-.908c0-.045-.045-.136-.09-.136-.228-.09-.908-.272-1.453-.272q-.953 0-1.498.408a1.3 1.3 0 0 0-.544 1.09c0 1.043.862 1.225 1.679 1.406.136.046.09.046.136.046.363.09.772.181.772.453 0 .091-.046.182-.091.227q-.136.136-.545.136c-.499 0-1.497-.227-1.77-.317-.045 0-.045.045-.045.045v.908c0 .045.045.136.09.136 0 0 .863.272 1.635.272 1.406 0 2.133-.544 2.133-1.543-.137-.454-.318-.817-.681-1.044"
        />
        <path
          fill="#fff"
          d="M10.376 14.207c-.091 0-.182-.046-.182-.182v-.544c0-.136.09-.227.227-.227h4.039c.136 0 .227.09.227.227v.499c0 .136-.045.181-.182.181h-4.13m3.631-1.406c-.045 0-.09 0-.09-.046v-.09c.136-.409.59-1.68.59-1.68.045-.09 0-.181-.091-.227l-.227-.09h-.046c-.045 0-.09 0-.09.045-.091.227-.137.363-.227.59 0 .045-.046.045-.091.045h-.454c-.045 0-.045 0-.09-.045v-.09c.135-.41.226-.727.408-1.09.045-.045.09-.182.272-.182h.09c.137.046.318.091.5.137.136.045.272.09.454.136.454.181.454.499.363.817-.045.181-.318.953-.5 1.407-.045.09-.045.181-.09.226 0 .046-.045.091-.136.091h-.545zm-1.86 0c-.091 0-.091-.091-.091-.137 0-.045-.046-2.405-.046-2.723 0-.045 0-.09.046-.09 0 0 .045-.046.09-.046h.636c.045 0 .045 0 .09.046.046.045.046.09.046.09 0 .318-.045 2.678-.045 2.723 0 0 0 .137-.091.137zm-1.861 0c-.091 0-.091-.046-.136-.091 0-.046-.046-.136-.091-.227-.182-.454-.409-1.18-.5-1.407-.09-.318-.09-.68.364-.817.136-.045.317-.09.454-.136.181-.045.363-.09.499-.136h.09c.137 0 .227.09.273.181.181.363.272.681.408 1.09v.09s-.045.046-.09.046h-.454c-.046 0-.091 0-.091-.046-.09-.227-.136-.408-.227-.59 0 0-.046-.045-.09-.045h-.046l-.227.09c-.09.046-.136.137-.09.228 0 0 .453 1.27.59 1.679v.09s-.046.046-.092.046c0-.045-.544-.045-.544-.045m2.133-3.45c-.046 0-.046 0-.091-.045a2.6 2.6 0 0 1-.5-.5s-.045-.045 0-.135c.137-.227.364-.363.545-.545h.091q.273.204.545.545.067.067 0 .136a2.6 2.6 0 0 1-.5.5c-.045.044-.045.044-.09.044"
        />
      </g>
      <defs>
        <radialGradient
          id="post_NL_svg__paint0_radial_13187_15760"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="translate(10.57 13.37)scale(22.303)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC429" />
          <stop offset={1} stopColor="#F26F23" />
        </radialGradient>
        <clipPath id="post_NL_svg__clip0_13187_15760">
          <path fill="#fff" d="M.979.32h34.043v33.674H.979z" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
export default SvgPostNl
