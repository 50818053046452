import Axios, { Canceler } from 'axios'

import {
  ACCOUNT,
  ACCOUNT_CHILDREN,
  CART,
  FORGOT_PASSWORD,
  REGISTER_PROTECTED_ROUTES,
  SIGNIN,
  SIGNUP,
} from '../../constants/routes'

//Standard libraries
import React, { useEffect } from 'react'

//Redux
import {
  isAutoLoginSelector,
  loginPendingSelector,
  loginStatusSelector,
  userInitStatusSelector,
} from '../../features/user/selector'

//Custom libraries
import { useDispatch, useSelector } from 'react-redux'

//Foundation libraries
import { AUTOLOGIN_LOGIN_ID_KEY, AUTOLOGIN_TOKEN_KEY, CHECKOUT } from '@constants/common'
import { forceLoggedCheckout, isRememberMeEnabledSelector } from '@redux/selectors/site'
import { useRouter } from 'next/router'
import { useSite } from '../hooks/useSite'
import { useCheckoutPaths } from '@foundation/hooks/useCheckoutPaths'
import { useSearchParams } from 'next/navigation'

import { login } from '@features/user/thunks/login'
import { useCSRForUser } from '@foundation/hooks/useCSRForUser'
import { genericErrorSelector } from '@features/error/selector'
import { storageSessionHandler } from '@foundation/utils/storageUtil'
import { SKIP_CREDENTIALS, SKIP_WC_TOKEN_HEADER } from '@foundation/constants/common'
import { useTranslation } from 'next-i18next'
import { orderItemsSelector } from '@features/order/selector'
import { useCart } from '@hooks/useCart'

const LoginGuard: React.FC = () => {
  const { mySite } = useSite()
  const router = useRouter()
  const userLoggedIn = useSelector(loginStatusSelector)
  const userInited = useSelector(userInitStatusSelector)
  const isForceLoggedCheckout = useSelector(forceLoggedCheckout)
  const CheckoutPaths = useCheckoutPaths()
  const searchParams = useSearchParams()
  const redirectRoute = searchParams.get('redirect')
  const partiallyAuthenticatedSearchParam = searchParams.get('isPartiallyAuthenticated')
  const { t } = useTranslation()

  const loginPending = useSelector(loginPendingSelector)
  const { isAuthenticationError } = useCSRForUser()
  const errorSelector = useSelector(genericErrorSelector)
  const isErrorLogin = isAuthenticationError(errorSelector)
  const isRememberMeEnabled = useSelector(isRememberMeEnabledSelector)
  const orderItems = useSelector(orderItemsSelector)
  const { cart } = useCart()
  const isAutologin = useSelector(isAutoLoginSelector)

  const dispatch = useDispatch()
  const widgetName = LoginGuard.name
  const CancelToken = Axios.CancelToken
  const cancels: Canceler[] = []

  const payloadBase: object = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
    ...(isRememberMeEnabled && { rememberMe: true, updateCookies: true }),
  }

  const redirectRouteMatcher = () => {
    const routes = [SIGNIN, SIGNUP, FORGOT_PASSWORD]
    const routerpath = router.pathname.split('/')
    return routes.some(route => routerpath.includes(route))
  }

  const handleRedirect = () => {
    let routeURL = `/${ACCOUNT}/${ACCOUNT_CHILDREN.DASHBOARD}`

    if (redirectRoute && redirectRoute.length > 0) {
      const isCartRoute = [CART, `/${CART}`].includes(redirectRoute)
      const isCheckoutRoute = redirectRoute === `/${CHECKOUT}`

      if ((isCartRoute || isCheckoutRoute) && orderItems?.length > 0) {
        routeURL = `/${CHECKOUT}`
      } else {
        routeURL = redirectRoute
      }
    }

    router.push(routeURL)
  }

  useEffect(() => {
    if (mySite && userInited) {
      const storeID = mySite.storeID
      let registerProtectedRoute: boolean = false

      if (isForceLoggedCheckout && router.pathname.includes(CHECKOUT) && !userLoggedIn) {
        let redirectRoute = `/${CHECKOUT}`

        if (router.pathname.includes(CheckoutPaths['order-confirmation'])) {
          const orderId = searchParams.get('resumeOrder')
          orderId && (redirectRoute = encodeURI(`${CheckoutPaths['order-confirmation']}?resumeOrder=${orderId}`))

          // Attempt autoLogin from url param tokens
          let loginId = searchParams.get(AUTOLOGIN_LOGIN_ID_KEY) || ''
          let loginToken = searchParams.get(AUTOLOGIN_TOKEN_KEY) || ''

          // searchParams.get decodes, encodeURI encodes to %20 instead of +
          loginId = loginId.replace(/ /g, '+')
          loginToken = loginToken.replace(/ /g, '+')

          const performLogin = async () => {
            storageSessionHandler.removeCurrentUser()
            dispatch(
              login({
                body: {
                  logonId: encodeURI(loginId),
                  logonPassword: encodeURI(loginToken),
                },
                [SKIP_WC_TOKEN_HEADER]: true,
                [SKIP_CREDENTIALS]: true,
                storeId: storeID,
                translation: t,

                isAutoLogin: true,
                ...payloadBase,
              })
            )
          }

          // auto login params are available and we're not logged-in or logging-in
          if (loginId && loginToken && !userLoggedIn && !loginPending && !isErrorLogin) {
            performLogin()
          }

          // auto login params are available and login failed
          if (loginId && loginToken && !userLoggedIn && !loginPending && isErrorLogin) {
            router.push({
              pathname: `/${SIGNIN}`,
              query: {
                redirect: redirectRoute,
              },
            })
          }

          // we're logged-in
          if (userLoggedIn) {
            router.push(`${CheckoutPaths['order-confirmation']}?resumeOrder=${orderId}`)
          }

          // auto login params are not available and we're not logged-in or logging-in
          if ((!loginId || !loginToken) && !loginPending && !userLoggedIn) {
            router.push({
              pathname: `/${SIGNIN}`,
              query: {
                redirect: redirectRoute,
              },
            })
          }
        } else {
          // original login guard logic
          if (!userLoggedIn) {
            router.push(`/${SIGNIN}`)
          }
        }
      }

      if (!userLoggedIn) {
        registerProtectedRoute = REGISTER_PROTECTED_ROUTES.some(b => {
          return router.pathname.includes(b)
        })
      }

      if (!!registerProtectedRoute) {
        router.push(`/${SIGNIN}`)
      }

      if (
        userLoggedIn &&
        !loginPending &&
        redirectRouteMatcher() &&
        !partiallyAuthenticatedSearchParam &&
        !isAutologin
      ) {
        handleRedirect()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, userLoggedIn, loginPending, mySite, userInited, orderItems, cart])

  return <></>
}

export default LoginGuard
