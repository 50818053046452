import React from 'react'
import Head from 'next/head'
import config from '@configs/index'
import Script from 'next/script'

interface SeoProps {
  extraJS?: string[]
  extraCSS?: string[]
}

export const Seo: React.FC<SeoProps> = ({ extraJS, extraCSS }) => {
  const cssLink = (link: string) => (
    <link
      key={link}
      rel="stylesheet"
      type="text/css"
      href={[config.cmsImageServerUrl, link].join('')}
    />
  )

  const jsTag = (src: string) => (
    <Script
      key={src}
      src={[config.cmsImageServerUrl, src].join('')}
      type="text/javascript"
    />
  )

  return (
    <Head>
      {extraJS && extraJS.map((el) => jsTag(el))}
      {extraCSS && extraCSS.map((el) => cssLink(el))}
    </Head>
  )
}
